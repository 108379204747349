export const Mission = () => {
    return (
        <>
          
          <div className="row" id="Mission">

<div className="col-12 col-md-6 col-lg-5 text-center mx-auto pt-3">
    <img src="Assets/Images/mission-01.png" alt="" className="ds missionsize"/>
</div>

<div className="col-12 col-md-10 col-lg-6 pt-5 pe-lg-5  alignment">
    <h3 className="fw-bold colorcharchol pt-2">Mission</h3>

    <div className="zoom-in-out-box2">
        <hr/>
    </div>
    <p className="pt-5 px-2 pe-lg-5">At CDAT, our mission is to deliver exceptional software solutions that empower businesses to succeed in a rapidly evolving digital landscape. With a focus on innovation, reliability, and customer satisfaction, we provide cutting-edge technology tailored to our clients' needs. Through expertise and commitment to excellence, we aim to be the preferred partner for organizations seeking growth, operational optimization, and a competitive edge.</p>
</div>


</div>
        </>
    )
}