export const AboutPage = () => {
    return (
        <>
            <div className="row pb-5 p-lg-5" id="about_us">
                {/* <div className="col-12 col-md-6 col-lg-6 px-4 px-lg-5 pt-lg-5 d-none d-lg-block ">
                    <img src="Assets/Images/seo-search-engine.jpg" alt="img not found" className="hroimgg " />
                </div> */}
                <div className="col-12 col-lg-6 px-4 px-lg-5 " >
                    <div className=" pt-4 pt-lg-5 ">
                        <h3 className="fw-bold green">ABOUT CDAT</h3>
                        <div className="zoom-in-out-box2">
                            <hr />
                        </div>
                        <h5 className="pt-4  py-1" style={{ color: "black", fontSize: '20px' }}>Welcome to CDAT, your trusted partner in delivering exceptional software solutions.</h5>
                        <p className="py-1 grays" style={{ textAlign: 'justify' }}> <b>CDAT</b> is the private company specialize in crafting customized software solutions for businesses of all sizes, blending technical proficiency with creative innovation.
                        </p>
                        <p className="grays mb-2" style={{ textAlign: 'justify' }}>
                            We have our proficiency in the sectors such as Healthcare, HRMS, E-Commerce, Water and Agriculture Manufacturing and Service Industry, e-Governance and Education.
                        </p>
                        <p className="grays mb-2" style={{ textAlign: 'justify' }}> We have partnered and worked with various government and private departments. </p>
                        <p className="grays mb-2" style={{ textAlign: 'justify' }}>Partner with us to unlock your business’s true potential and drive transformative growth.</p>

                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-5 ms-lg-5 px-4 px-lg-0">
                    <img src="Assets/Images/building-01.jpg" alt="img not found" className="hroimgg2 " />
                </div>

            </div>
            {/* <!--end of about us--> */}
        </>
    )
}