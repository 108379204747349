export const ApplyNow = () => {
  return (
    <>
      <div className="row" id="forminput">
        <div className="col-10 col-md-8 py-5 get-in-touch m-auto">
          <h4 className="title"><b>Apply Now</b></h4>
          <form className="contact-form row" action="https://formspree.io/f/xrgwjprn" method="post"
            enctype="multipart/form-data">

            <div className="form-field col-lg-6">
              <input id="Client_Name" name="Client_Name" className="input-text js-input" type="text" required />
              <label className="label" for="Client_name">Name <span className="text-danger">*</span> </label>
            </div>

            <div className="form-field col-lg-6">
              <input id="Client_mobile" name="Client_Mobile" className="input-text js-input" type="tel" 
                minlength="10" maxlength="10" required />
              <label className="label" for="Client_mobile">Mobile <span className="text-danger">*</span></label>
            </div>

            <div className="form-field col-lg-6">
              <input id="Client_email" name="Client_Email" className="input-text js-input" type="email" required />
              <label className="label " for="Client_email">E-mail <span className="text-danger">*</span></label>
            </div>

            <div className="form-field col-lg-6 ">
              <input id="Client_Qualification" name="Client_Qualification" className="input-text js-input" type="text" />
              <label className="label" for="Client_Qualification">Qualification <span className="text-danger">*</span></label>
            </div>

            <div className="form-field col-lg-6">
              <input id="address" name="Address" className="input-text js-input" type="text" required />
              <label className="label" for="address">Current Address <span className="text-danger">*</span></label>
            </div>

            <div className="form-field col-lg-6">
              <input id="permanent_address" name="Permanent_Address" className="input-text js-input" type="text" required />
              <label className="label" for="permanent_address">Permanent Address <span className="text-danger">*</span></label>
            </div>

            <div className="form-field col-lg-6">
              <input id="Client_Experiance" name="Client_Experiance" className="input-text js-input" type="text" required />
              <label className="label" for="Client_Experiance">
                Job Experiance <span className="text-danger">*</span> (months/ years)</label>
            </div>


            <div className="form-field col-lg-6">
              <input id="Client_message" name="Client_Message" className="input-text js-input" type="text" required />
              <label className="label" for="Client_message">Message</label>
            </div>

            <div className="form-field col-lg-5 py-2">
              <div className="select">
                <select name="Position">
                  <option value="not_Select">Select Position </option>
                  <option value=".NET Developer">ASP .NET Developer</option>
                  <option value="Full_Stack_React_JS_/_Node_JS">Full Stack React JS / Node JS</option>
                  <option value="Application_Support_Engineer">Application Support Engineer</option>
                  <option value="Business_Analyst">Business Analyst</option>
                  <option value="Business_Development_Manager">Business Development Manager</option>
                  <option value="React_Developer">React Developer</option>
                  <option value="UI_/_UX_Designer">UI/ UX Designer</option>
                  <option value="QC_Engineer">QC Engineer</option>
                  <option value="Digital_Marketing_Executive">Digital Marketing Executive </option>
                </select>
              </div>
            </div>

            <div className="form-field col-lg-6 py-2 border border-1">
              <input id="RESUME" name="RESUME" className="input-text js-input mt-1" type="file" required />
              <label for="RESUME">Upload Resume <span className="text-danger">*</span></label>
            </div>




            <div className="form-field col-lg-12">
              <input className="submit-btn" type="submit" value="Submit" onsubmit="return validateForm()" />
            </div>
          </form>
        </div>

        <div className="popup" id="successPopup">
          <div className="popup-content">
            <span className="close" onclick="closePopup()"><img src="img/popup/close.png" alt="" style={{ width: '20px' }} />

            </span>
            {/* <!-- <img src="check-mark.png" alt="" style="width: 20%" className="checkpopimg"> --> */}
            <h2 className="heading">Success!</h2>
            <p>Your form has been submitted successfully.</p>
          </div>
        </div>
      </div>
    </>
  )
}