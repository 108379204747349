import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const EmployeeGrievanceMonitoringSystem = () => {
    return (
        <>
            <Header active="Products" />
            <HeroSection />
            {/* ----------------------------------- */}


            <div className="row bgdotimg " id="AMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Employee Grievance Monitoring System</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        Addressing and resolving employee grievances efficiently helps an organization ensure a positive work environment. It is essential for maintaining a harmonious, productive, and compliant work environment. It helps prevent issues from becoming major problems, ensures employees are heard, and supports long-term organizational success. Therefore, to stream line all these processes we have developed Employee Grievance Monitoring System where Employee can file grievances under appropriate category in Multiple modes for complaints. Employees can track grievances in real-time through a unified portal. All grievances from various sources are integrated, with an automated workflow for online forwarding. Grievances are categorized based on timelines, and once submitted, they are automatically assigned to the relevant Zone officer.
                    </p>

                    {/* <p>
                        It provides a comprehensive platform for daily employee performance tracking, allowing recording of achievements and punishments. Timely bulletin updates are facilitated within predefined schedules. With a user-friendly interface accessible on various devices, it's an invaluable tool for efficient administrative tasks, offering precise financial year-based data segmentation for reporting and performance analysis.
                    </p> */}
                </div>
            </div>
            {/* <!--end of AMS sopi--> */}


            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Web Portal and Mobile App for Grievance Registration and Grievance Redressal </b> </p>
                    </div>
                </div>
                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Grievance Escalation facility </b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Feedback and rating facility for the employee</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Covers various types of grievances </b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Real time tracking of grievances</b></p>
                    </div>
                </div>


            </div>
            {/* <!--end of row key features--> */}


            {/* <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/ASSET_MANAGEMENT_SYSTEM.pdf')} 
                      className="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div> */}

            {/* <!--end of Brochure row--> */}


            {/* --------------------------------- */}
            <Footer />
        </>
    )
}