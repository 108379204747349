export const Vision = () => {
    return (
        <>
            <div className="row" id="vision">


                <div className="col-12  col-md-5 col-lg-5 py-5 text-center  mx-auto">
                    <img src="Assets/Images/why us 1.png" alt="" style={{height: '35vh' }} className="ds" />
                </div>
                <div className="col-12 col-lg-6 py-5 px-lg-5 mx-auto  alignment">
                    <h3 className="fw-bold colorcharchol text-center">Vision</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                    <p className="py-5 pt-lg-5 pb-lg-0 px-2 ">CDAT Pune's vision is to revolutionize industries by harnessing AI, automation, and data science to drive efficiency, innovation, and informed decision-making, empowering businesses to unlock their full potential through intelligent, data-driven solutions.</p>
                </div>
            </div>
        </>
    )
}