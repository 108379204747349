import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"


export const RubberPlantationMonitoringSystem = () => {
    return (
        <>
            <Header active="Products" />
            <HeroSection />

            {/* --------------------------------- */}
            <div class="row bgdotimg " id="AMS">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold colorcharchol text-center">Rubber Plantation Monitoring System for Rubber Board</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-10 mx-auto py-3 text-center">
                    <p class="alignment text-center">
                        The expansion of plantations in the year 2024-25 required regular monitoring of nurseries for supply of healthy rubber saplings and plantation activities undertaken by the beneficiaries as per standard practices. The monitoring activities at nursery and plantation sites required regular data collection at each site based on the identified parameters.
                    </p>
                    <p>
                        In this regard, CDAT collaborated with RUBBER BOARD and developed a mobile app and dashboard to capture data on plantations and nurseries. This application can show data entry related to rubber plantation and nurseries. Through this application process for registration, verification and acknowledgement of nurseries from various offices gets easier and smoother. </p>

                </div>

            </div>

            <div class="row py-5 px-3" id="key">
                <div class="col-12 col-md-10 mx-auto py-1">
                    <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Field Officer can enter rubber plantations and nurseries details through mobile app.</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Offline data saving facility the data automatically syncs whenever in network area.</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Dynamic Dashboard presents key data parameters and downloadable reports.</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Dashboard projects data from each level along with the total aggregate figures.</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Dashboard highlights key details as received from the mobile app. </b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Facility to upload bulk data in excel format from PC / laptop.</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            {/* <div class="row" id="Brochure">
                <div class="col-12 charchole py-5">
                    <h4 class="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p class="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div class="py-4 text-center">
                        <a onClick={() => window.open('http://cdat.co.in/doc/Tree_Risk_Assessment_System.pdf')}
                            class="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div> */}
            {/* <!--end of Brochure row--> */}



            <Footer />
        </>
    )
}