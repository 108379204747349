import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const GGCI = () => {
    return (
        <>
            <Header active="Products" />
            <HeroSection />
            {/* ----------------------------------- */}


            <div className="row bgdotimg " id="AMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Global Green Credit Initiative Government of India</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        The Global Green Credit Initiative is an international platform fostering environmental sustainability and collaboration among individuals, corporations, and governments. It enables users to register, share technical reports, case studies, and multimedia showcasing environmental efforts. With features like admin-reviewed content approval, global visibility, and real-time content management, the platform promotes transparency and engagement. By encouraging the exchange of innovative ideas and tools, it empowers stakeholders to contribute to a greener, healthier planet.
                    </p>

                    {/* <p>
                        It provides a comprehensive platform for daily employee performance tracking, allowing recording of achievements and punishments. Timely bulletin updates are facilitated within predefined schedules. With a user-friendly interface accessible on various devices, it's an invaluable tool for efficient administrative tasks, offering precise financial year-based data segmentation for reporting and performance analysis.
                    </p> */}
                </div>
            </div>
            {/* <!--end of AMS sopi--> */}


            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>World Wide User Registration</b> </p>
                    </div>
                </div>
                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Admin Approval Process</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Content Upload Facility for Partner</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Review and Publishing by Admin</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Global Visibility</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Content Management</b></p>
                    </div>
                </div>

            </div>
            {/* <!--end of row key features--> */}


            {/* <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/ASSET_MANAGEMENT_SYSTEM.pdf')} 
                      className="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div> */}

            {/* <!--end of Brochure row--> */}


            {/* --------------------------------- */}
            <Footer />
        </>
    )
}