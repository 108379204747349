import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"
import { GetIntouchForm } from "../MainHomePage/GetIntouchForm"
import { MapWithConactInfo } from "./MapWithConactInfo"
import { SupportNumber } from "./SupportNumber"

export const ContactPage = () => {
    return (
        <>
            <Header active="Contact" />
            <HeroSection />
            <SupportNumber />
            <MapWithConactInfo />
            <GetIntouchForm />
            <Footer />
        </>
    )
}