import {
    Route,
    BrowserRouter as Router,
    Routes


} from "react-router-dom";

import { Header } from "./AllComponent/Header.js";
import { Footer } from "./AllComponent/Footer.js";
import { MainHomePage } from "./AllComponent/MainHomePage/MainHomePage.js";
import { ContactPage } from "./AllComponent/ContactPage/ContactPage.js";
import { CareerPage } from "./AllComponent/CareerPage/CareerPage.js";
import { Blog } from "./AllComponent/BlogPage/Blog.js";
import { ClientsPage, OurClients } from "./AllComponent/ClientsPage/OurClients.js";
import { ClientReviews } from "./AllComponent/ClientsPage/ClientReviews.js";
import { WhoWeAre } from "./AllComponent/AboutPage/WhoWeAre/WhoWeAre.js";
import { WhatWeDo } from "./AllComponent/AboutPage/WhatWeDo/WhatWeDo.js";
import { WhyUs } from "./AllComponent/AboutPage/WhyUs/WhyUs.js";
import { Culture } from "./AllComponent/AboutPage/Culture/Culture.js";
import { Events } from "./AllComponent/Events/Events.js";
import { Egovernance } from "./AllComponent/Expertise/Egovernance/Egovernance.js";
import { Healthcare } from "./AllComponent/Expertise/Healthcare/Healthcare.js";
import { ManufacturingAndServicesIndustry } from "./AllComponent/Expertise/ManufacturingAndServicesIndustry/ManufacturingAndServicesIndustry.js";
import { FinancialServices } from "./AllComponent/Expertise/FinancialServices/FinancialServices.js";
import { E_Commerce } from "./AllComponent/Expertise/E-Commerce/E_Commerce.js";
import { HRMS } from "./AllComponent/Expertise/HRMS/HRMS.js";
import { Agriculture } from "./AllComponent/Expertise/Agriculture/Agriculture.js";
import { BiSolutions } from "./AllComponent/Services/BiSolutions/BiSolutions.js";
import { DataAnalytics } from "./AllComponent/Services/DataAnalytics/DataAnalytics.js";
import { WebsiteDevelopment } from "./AllComponent/Services/WebsiteDevelopment/WebsiteDevelopment.js";
import { MobileAppDevelopment } from "./AllComponent/Services/MobileAppDevelopment/MobileAppDevelopment.js";
import { GraphicDesign } from "./AllComponent/Services/GraphicDesign/GraphicDesign.js";
import { CustomizedSolution } from "./AllComponent/Services/CustomizedSolution/CustomizedSolution.js";
import { ProductDevelopment } from "./AllComponent/Services/ProductDevelopment/ProductDevelopment.js";
import { ProductMaintenance } from "./AllComponent/Services/ProductMaintenance/ProductMaintenance.js";
import { Sopi } from "./AllComponent/Products/Sopi.js";
import { Mrms } from "./AllComponent/Products/Mrms.js";
import { Ams } from "./AllComponent/Products/Ams.js";
import { Lfms } from "./AllComponent/Products/Lfms.js";
import { Etapal } from "./AllComponent/Products/Etapal.js";
import { MedicineStockManageSystem } from "./AllComponent/Products/MedicineStockManageSystem.js";
import { PublicGrievance } from "./AllComponent/Products/PublicGrievance.js";
import { SmartLaborApplication } from "./AllComponent/Products/SmartLaborApplication.js";
import { Sewerage } from "./AllComponent/Products/Sewerage.js";
import { RoadWork } from "./AllComponent/Products/RoadWork.js";
import { STP } from "./AllComponent/Products/STP.js";
import { Garden } from "./AllComponent/Products/Garden.js";
import { WaterSupply } from "./AllComponent/Products/WaterSupply.js";
import { PTMS } from "./AllComponent/Products/PTMS.js";
import { ERP } from "./AllComponent/Products/ERP.js";
import { AgricultureERP } from "./AllComponent/Products/AgricultureERP.js";
import { GlobalKisanMarket } from "./AllComponent/Products/GlobalKisanMarket.js";
import { PoliceGazette } from "./AllComponent/Products/PoliceGazette.js";
import { HealthCibil } from "./AllComponent/Products/HealthCibil.js";
import { NursingHome } from "./AllComponent/Products/NursingHome.js";
import { HRMSystem } from "./AllComponent/Products/HRMSystem.js";
import { TermsConditions } from "./AllComponent/TermsConditions.js";
import { PrivacyPolicy } from "./AllComponent/PrivacyPolicy.js";
import { Sitemap } from "./AllComponent/Sitemap.js";
import { Headers } from "./AllComponent/Headers.js";
import { Products } from "./AllComponent/Products/Products.js";
import { AmchiMulgi } from "./AllComponent/Products/AmchiMulgi.js";
import { TreeRiskAssessmentSystem } from "./AllComponent/Products/TreeRiskAssessmentSystem.js";
import { VanPathSurakshak } from "./AllComponent/Products/VanPathSurakshak.js";
import { RubberPlantationMonitoringSystem } from "./AllComponent/Products/RubberPlantationMonitoringSystem.js";
import { AssetManagementSystem } from "./AllComponent/Products/AssetManagementSystem.js";
import { Encroachment } from "./AllComponent/Products/Encroachment.js";
import { GPF } from "./AllComponent/Products/GPF.js";
import { GGCI } from "./AllComponent/Products/GGCI.js";
import { EmployeeGrievanceMonitoringSystem } from "./AllComponent/Products/EmployeeGrievanceMonitoringSystem.js";
import { TreeMonitoringSystem } from "./AllComponent/Products/TreeMonitoringSystem.js";




export const AllRoutes = () => {



    return (
        <>
            <Router>
                <Routes>

                    <Route exact path="/" element={<MainHomePage />} />
                    <Route exact path="/Header" element={<Header />} />
                    <Route exact path="/Footer" element={<Footer />} />
                    <Route exact path="/OurClients" element={<OurClients />} />
                    <Route exact path="/ClientReviews" element={<ClientReviews />} />
                    <Route exact path="/Blog" element={<Blog />} />
                    <Route exact path="/CareerPage" element={<CareerPage />} />
                    <Route exact path="/WhoWeAre" element={<WhoWeAre />} />
                    <Route exact path="/WhatWeDo" element={<WhatWeDo />} />
                    <Route exact path="/WhyUs" element={<WhyUs />} />
                    <Route exact path="/Culture" element={<Culture />} />
                    <Route exact path="/Events" element={<Events />} />
                    <Route exact path="/Egovernance" element={<Egovernance />} />
                    <Route exact path="/Healthcare" element={<Healthcare />} />
                    <Route exact path="/FinancialServices" element={<FinancialServices />} />
                    <Route exact path="/E_Commerce" element={<E_Commerce />} />
                    <Route exact path="/HRMS" element={<HRMS />} />
                    <Route exact path="/Agriculture" element={<Agriculture />} />
                    <Route exact path="/BiSolutions" element={<BiSolutions />} />
                    <Route exact path="/DataAnalytics" element={<DataAnalytics />} />
                    <Route exact path="/WebsiteDevelopment" element={<WebsiteDevelopment />} />
                    <Route exact path="/MobileAppDevelopment" element={<MobileAppDevelopment />} />
                    <Route exact path="/GraphicDesign" element={<GraphicDesign />} />
                    <Route exact path="/CustomizedSolution" element={<CustomizedSolution />} />
                    <Route exact path="/ProductDevelopment" element={<ProductDevelopment />} />
                    <Route exact path="/ProductMaintenance" element={<ProductMaintenance />} />
                    <Route exact path="/Sopi" element={<Sopi />} />
                    <Route exact path="/Mrms" element={<Mrms />} />
                    <Route exact path="/Ams" element={<Ams />} />
                    <Route exact path="/Lfms" element={<Lfms />} />
                    <Route exact path="/AmchiMulgi" element={<AmchiMulgi />} />
                    <Route exact path="/Etapal" element={<Etapal />} />
                    <Route exact path="/MedicineStockManageSystem" element={<MedicineStockManageSystem />} />
                    <Route exact path="/PublicGrievance" element={<PublicGrievance />} />
                    <Route exact path="/SmartLaborApplication" element={<SmartLaborApplication />} />
                    <Route exact path="/Sewerage" element={<Sewerage />} />
                    <Route exact path="/RoadWork" element={<RoadWork />} />
                    <Route exact path="/STP" element={<STP />} />
                    <Route exact path="/Garden" element={<Garden />} />
                    <Route exact path="/WaterSupply" element={<WaterSupply />} />
                    <Route exact path="/PTMS" element={<PTMS />} />
                    <Route exact path="/ERP" element={<ERP />} />
                    <Route exact path="/AgricultureERP" element={<AgricultureERP />} />
                    <Route exact path="/GlobalKisanMarket" element={<GlobalKisanMarket />} />
                    <Route exact path="/GlobalKisanMarket" element={<GlobalKisanMarket />} />
                    <Route exact path="/PoliceGazette" element={<PoliceGazette />} />
                    <Route exact path="/HealthCibil" element={<HealthCibil />} />
                    <Route exact path="/HRMSystem" element={<HRMSystem />} />
                    <Route exact path="/NursingHome" element={<NursingHome />} />
                    <Route exact path="/TermsConditions" element={<TermsConditions />} />
                    <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                    <Route exact path="/Sitemap" element={<Sitemap />} />
                    <Route exact path="/Products" element={<Products />} />
                    <Route exact path="/TreeRiskAssessmentSystem" element={<TreeRiskAssessmentSystem />} />
                    <Route exact path="/VanPathSurakshak" element={<VanPathSurakshak />} />
                    <Route exact path="/RubberPlantationMonitoringSystem" element={<RubberPlantationMonitoringSystem />} />
                    <Route exact path="/AssetManagementSystem" element={<AssetManagementSystem />} />
                    <Route exact path="/Encroachment" element={<Encroachment />} />
                    <Route exact path="/GPF" element={<GPF />} />
                    <Route exact path="/GGCI" element={<GGCI />} />
                    <Route exact path="/EmployeeGrievanceMonitoringSystem" element={<EmployeeGrievanceMonitoringSystem />} />
                    <Route exact path="/TreeMonitoringSystem" element={<TreeMonitoringSystem />} />
                    
                    
                    <Route exact path="/ManufacturingAndServicesIndustry" element={<ManufacturingAndServicesIndustry />} />
                    <Route exact path="/ContactPage" element={<ContactPage />} />


                </Routes>



            </Router>

        </>
    )
}