export const CultureEvent = () => {
    return (
        <>
            <div className="row p-lg-5">
               

                <div className="col-12 col-lg-6 p-5">
                <div className=" py-4">
                    <h3 className="fw-bold colorcharchol ">Culture</h3>
                    <div className="zoom-in-out-box2">
                        <hr />
                    </div>
                </div>

                    <p className="" style={{ textAlign: 'justify' }}>Our workplace environment fosters creativity, enhances employee engagement, and supports the successful development of high-quality software. To promote team bonding, the company organizes a variety of events.</p>
                </div>

                <div className="col-12  px-lg-5 col-lg-6 p-3">
                    <img src="Assets/Images/event/Culture-01.png" alt="" className=" Culture" />
                </div>


            </div>
        </>
    )
}