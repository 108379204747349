import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const TreeMonitoringSystem = () => {
    return (
        <>
            <Header active="Products" />
            <HeroSection />
            {/* ----------------------------------- */}


            <div className="row bgdotimg " id="AMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">	Tree Monitoring System (Tree Inventory, Tree Cutting Tree Trimming, Tree Transplanting)</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                    The proposed system is a centralized web and mobile-based application designed to streamline the No Objection Certificate (NoC) process for tree cutting, trimming, and transplantation in the PCMC area. The system caters to Citizens, Horticulture Supervisors, Garden Department officials, and the PCMC Tree Authority. This system aims to maintain monitor and track Tree Inventory, Tree Geo-Tagging, Tree Cutting, Tree Trimming and Tree Transplanting. This system seamlessly integrate technology into environmental management, allowing for precise monitoring of tree plantations.
                       </p>

                    {/* <p>
                        It provides a comprehensive platform for daily employee performance tracking, allowing recording of achievements and punishments. Timely bulletin updates are facilitated within predefined schedules. With a user-friendly interface accessible on various devices, it's an invaluable tool for efficient administrative tasks, offering precise financial year-based data segmentation for reporting and performance analysis.
                    </p> */}
                </div>
            </div>
            {/* <!--end of AMS sopi--> */}


            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Environmental Sustainability </b> </p>
                    </div>
                </div>
                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Real-Time Tracking </b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Geo-Tagging and Monitoring</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Tree Inventory Management</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Role-Based Accessibility</b></p>
                    </div>
                </div>
                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>	Centralized Platform</b></p>
                    </div>
                </div>

            </div>
            {/* <!--end of row key features--> */}


            {/* <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/ASSET_MANAGEMENT_SYSTEM.pdf')} 
                      className="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div> */}

            {/* <!--end of Brochure row--> */}


            {/* --------------------------------- */}
            <Footer />
        </>
    )
}