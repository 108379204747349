export const Clients = () => {
    return (
        <div className="container-fluid font">


            <div className="row py-3" id="Central_and_State_clients">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="colorcharchol text-center"><b>Central & State Government</b></h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="row px-5 mx-auto w-100 ">
                    <div className="col-12 col-md-4 col-lg-2  my-4 " >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/nirdpr-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole ds ">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">National
                                Institute of Rural Development and
                                Panchayati Raj</span>
                        </div>
                    </div>

                  
                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/slna_tripura-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                State Level Nodal Agency Tripura</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/VanpthakSurakshak-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Van Path सु-Rakshak</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/SLNA_Uattar_Pradesh_Watershed_Management-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                SLNA Uttar Pradesh Watershed Management</span>
                        </div>
                    </div>


                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/Soil&Water_Conservation-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Soil & Water Conservation Department Government of Meghalaya  </span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4">
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/Rajiv_Gandhi_Mission-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Rajiv Gandhi Mission for Watershed Management Madhya Pradesh </span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/Department_of_Agriculture_Odisha-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Department of Agriculture & Farmer's Empowerment Government of Odisha</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/chhattisgad-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Government of Chhattisgarh</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/bihari_watershed-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Bihar Watershed Development Society</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/nhm-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                National Health Mission Maharashtra</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/adivasi_vibhag-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Department of Tribal Development</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/Agriculture _Department-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className="charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Agriculture Department Government of Maharashtra</span>
                        </div>
                    </div>

                    {/* <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/mahatma phule_krishi_vidhyapith-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Mahatma Phule Krishi Vidyapeeth</span>
                        </div>
                    </div> */}

                    <div className="col-12 col-md-4 col-lg-2 my-4" >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/mazi_mulgi-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Public Grievance In Aamchi Mulgi Scheme</span>
                        </div>
                    </div>

                </div>
            </div>
            {/* <!--end of Central_and_State_clients clients--> */}




            <div className="row py-3" id="Corporate_Client_clients">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="colorcharchol text-center"><b>Corporate Client's</b></h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="row px-5 mx-auto w-100 ">
                    <div className="col-12 col-md-4 col-lg-2  my-4 " >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/samarth_erp-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole ds ">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">Samarth Security System (India) Pvt. Ltd.</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/gkmarket-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Global Kisan Market</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/RFC-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Royal Fertilizers & Chemicals</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/palvi_agri-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Palvi Agro Chemicals & Fertilizers</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/DYP-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Tree Monitoring System</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/milso-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Marine Insurance Learning & Sharing Online</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/Sunrich-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Sunrich Agro Industries</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/ps_agri-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Plant Soul Exports</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/erp_gayatari-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Gayatri Micro Elements & Chemicals</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/GCPI_logo-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            Global Green Credit Initiative</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/ey-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                            ERNST & YOUNG, INDIA</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/placewell-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Placewell Industries Services PVT LTD</span>
                        </div>
                    </div>


                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/kpmg-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                KPMG International Limited </span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/mars-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                MARS Telecom Systems Pvt. Ltd.</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/stem-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                STEM</span>
                        </div>
                    </div>


                </div>
            </div>
            {/* <!--end of Corporate Client clients--> */}



            <div className="row py-3" id="zilla_Parishad_clients">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="colorcharchol text-center"><b>Zilla Parishad</b></h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="row px-5 mx-auto w-100 ">
                    <div className="col-12 col-md-4 col-lg-2  my-4   " >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_pune-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole ds ">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">Pune Zilla Parishad</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_nashik-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Nashik Zilla Parishad</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_raigad-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Raigad Zilla Parishad</span>
                        </div>
                    </div>


                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_wardha-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Wardha Zilla Parishad </span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_solapur-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Solapur Zilla Parishad</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_dhule-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Dhule Zilla Parishad</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_ratnagiri-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Ratnagiri Zilla Parishad</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_nanded-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Nanded Zilla Parishad</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_gadchiroli-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Gadchiroli Zilla Parishad</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/zp_osmanabad-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Osmanabad Zilla Parishad</span>
                        </div>
                    </div>

                </div>
            </div>
            {/* <!--end of zilla_Parishad clients--> */}


            <div className="row py-3" id="Muncipal_Corporation_clients">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="colorcharchol text-center"><b>Muncipal Corporation</b></h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="row px-5 mx-auto w-100 ">
                    <div className="col-12 col-md-4 col-lg-2  my-4   " >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/navi_mumbai_muncipal_corporation-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole ds ">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">Navi Mumbai Muncipal Corporation</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2  my-4   " >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/pcmc-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole ds ">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">Pimpri-Chinchwad Municipal Corporation</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2  my-4   " >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/malegaon-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole ds ">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">Malegaon Municipal Corporation</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/nagpur_muncipal_corporation-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Nagpur Muncipal Corporation</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/mira_bhaindar-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Mira Bhaindar Muncipal Corporation</span>
                        </div>
                    </div>


                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/Bhivandi_Nijampur_Municipal_Corporation-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Bhivandi Nijampur Municipal Corporation </span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/Nashik_Municipal_Corporation-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Nashik Municipal Corporation</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/AMC_muncipal_corporation-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Chhatrapati Sambhajinagar Municipal Corporation</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/pune_metro-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Pune Metropolitan Region Development Authority</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--end of Muncipal Corporation clients--> */}


            <div className="row py-3" id="Maharashtra_Police_clients">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="colorcharchol text-center"><b>Maharashtra Police</b></h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="row px-5 mx-auto w-100 ">
                    <div className="col-12 col-md-4 col-lg-2  my-4   " >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/pcmc_commissioner-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole ds ">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">PCMC Commissioner</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/igp_kolhapur-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                IGP Kolhapur</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/igp_nashik-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                IGP Nashik</span>
                        </div>
                    </div>


                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/sp_solapur-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                SP Solapur </span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/sp_nandurbar-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                SP Nandurbar</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/sp_Nagar-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                SP Nagar</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/sp_dhule-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                SP Dhule</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"  >
                        <div className=" card-block  text-white text-center border">
                        <img src="Assets/Images/clients_all/nashik_city_police-01.png" alt="img not found" class="drops m-auto ds card1"
                           style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Nashik City Police</span>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 my-4"   >
                        <div className=" card-block  text-white text-center border">

                            <img src="Assets/Images/clients_all/msphc-01.png" alt="img not found" className="drops m-auto ds card1"
                                style={{ width: '100%' }} />
                        </div>
                        <div className=" charchole  ds">
                            <span className=" text-center py-4  my-0 client_text px-2  text-white ">
                                Maharashtra State Police Housing & Welfare Corporation Ltd</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--end of Maharashtra Police clients--> */}






        </div>
    )
}