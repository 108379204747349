import { useNavigate } from "react-router-dom"

export const Partner = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="row charchole pb-5 px-lg-4" id="partners">

        <div className="col-12 col-lg-11 m-auto pt-5 text-center">
          <h3 className="fw-bold text-white">OUR PARTNERS</h3>

          <div className="zoom-in-out-box2 m-auto">
            <hr />
          </div>

          <p className=" text-center text-white pt-3">
          At CDAT, we believe in the power of collaboration. These partnerships are key to our success, allowing us to deliver exceptional products and services to our clients. We are proud to work alongside some of the most trusted names in the industry. Together, we share a commitment to excellence, innovation, and customer satisfaction. </p>
        </div>


        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4 "> 
          <a href="javascript:void(0)"
            onClick={() => window.open('http://nirdpr.org.in/')}
            //  href="http://nirdpr.org.in/"
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/nirdpr-01.png" alt="img not found" className="drops" />
              <p className="card-text1  text-center py-2">C GARD NIRD & PR HYDERABAD</p>
            </div>
          </a>

        </div>

        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4">
          <a href="javascript:void(0)"
            onClick={() => window.open('https://www.ey.com/en_in')}
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/ey-01.png" alt="img not found" className="drops" />
              <p className="card-text1 text-center pt-2">
                ERNST & YOUNG, <br /> INDIA</p>
            </div>
          </a>
        </div>

        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4">
          <a href="javascript:void(0)"
            onClick={() => window.open('https://kpmg.com/xx/en/home.html')}
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/kpmg_partner-01.png" alt="img not found" className="drops" />
              <p className="card-text1 text-center py-2">KPMG ADVISORY SERVICES PVT. LTD</p>
            </div>
          </a>
        </div>

        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4">
          <a href="javascript:void(0)"
            onClick={() => window.open('https://www.vitalstrategies.org/')}
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/vital_strategy-01.png" alt="img not found" className="drops" />
              <p className="card-text1 text-center pt-2"> VITAL <br /> STRATEGIES</p>
            </div>
          </a>

        </div>

        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4">
          <a href="javascript:void(0)"
            onClick={() => window.open('https://www.mars-india.com/')}
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/mars-01.png" alt="img not found" className="drops" />
              <p className="card-text1 text-center pt-2"> MARS <br /> DRIVEN TO EVOLVE</p>
            </div>
          </a>

        </div>

        <div className="col-10 col-md-4 col-lg-2 m-auto mt-4">
          <a href="javascript:void(0)"
            onClick={() => window.open('https://www.treecotech.com/')}
            target="_blank">
            <div className="card1 card-block bg-row text-white">
              <img src="Assets/Images/partner/treecotech-01.png" alt="img not found" className="drops" />
              <p className="card-text1 text-center pt-2"> ARBORICULTURE CONSULTANCY </p>
            </div>
          </a>

        </div>
      </div>
    </>
  )
}