import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { InfoDataAnalytics } from "./InfoDataAnalytics"

export const DataAnalytics =()=>{
    return(
        <>
          <Header active="Home"/>
            <HeroSection/>
            <InfoDataAnalytics/>
            <BrightFutureTogether/>
            <Footer/>
        </>
    )
}