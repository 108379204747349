import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"


export const TreeRiskAssessmentSystem = () => {
    return (
        <>
            <Header active="Products" />
            <HeroSection />

            {/* --------------------------------- */}
            <div class="row bgdotimg " id="AMS">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold colorcharchol text-center">Tree Risk Assessment System</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-10 mx-auto py-3 text-center">
                    <p class="alignment text-center">
                        Tree Risk Assessment system is for the Tree Care Industry and arboriculture field related organization or Arboriculture Consultancy services organization catering to all government, semi-government, private entities like corporates, architects, project management consultants etc. where the Arborist can perform his Tree Risk Assessment and generate the necessary tree risk assessment report through this system. One can select tree location on google map and locate the tree on the google map. Trees can be mapped in different colors on the map for their unique identification.  </p>
                    <p>
                        The system is specially designed to facilitate the collaborative work of Arborist with his dedicated organization in the unchallenging and systematic manner. A detailed analysis of tree can be done through this system to evaluate the overall structure and condition of the tree and suggest the preventive measures. This system will provide the facility to generate report for individual assessment of the tree.
                    </p>

                </div>

            </div>
            {/* <!--end of AMS sopi--> */}


            <div class="row py-5 px-3" id="key">
                <div class="col-12 col-md-10 mx-auto py-1">
                    <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Tree inventory with geo-tagging</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Qualitative data analysis</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Advance risk analysis using Resistograph</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Job creation and assignment</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Job re-opening and re-completion</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Tree Mitigation Identification</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            <div class="row" id="Brochure">
                <div class="col-12 charchole py-5">
                    <h4 class="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p class="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div class="py-4 text-center">
                        <a onClick={() => window.open('http://cdat.co.in/doc/Tree_Risk_Assessment_System.pdf')}
                            // href="http://new.cdatinfotech.com/doc/Public_Grievance.pdf"
                            class="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}



            <Footer />
        </>
    )
}