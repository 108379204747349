export const FoundationDay = () => {
    return (
        <>
            <div className="row p-5" id="event_photo_five"  style={{background:"#d3d3d3"}}>

                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Foundation Day</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3 text-center">
                        {/* <span className="yearwise">2024</span> */}
                        <div>
                            <a href="Assets/Images/event/foundation_day.jpg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/foundation_day.jpg " alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3 text-center">

                        <div>
                            <a href="Assets/Images/event/foundation1-01.jpg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/foundation1-01.jpg " alt="" />
                                </span>
                            </a>
                        </div>
                        {/* <span className="yearwiseend">2023</span> */}
                    </div>
                </div>


                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3 text-center">
                        {/* <span className="yearwise">2022</span> */}
                        <div>
                            <a href="Assets/Images/event/foundation-01-min.jpg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/foundation-01-min.jpg" alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3 text-center">
                        <div>
                            <a href="Assets/Images/event/foundation4-01.jpg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/foundation4-01.jpg" alt="" />
                                </span>
                            </a>
                        </div>
                        {/* <span className="yearwiseend">2023</span> */}
                    </div>
                </div>

            </div>
        </>
    )
}