import { useNavigate } from "react-router-dom"

export const DomainWeWork = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="row p-3 px-lg-5 pt-lg-5 pt-5 text-center" style={{ backgroundColor: '#eeeeee' }}>

        <div className="col-12 m-auto  text-center ">
          <h3 className="fw-bold " style={{textTransform:"uppercase"}}> Our Industry Expertise and Solutions </h3>
          <div className="zoom-in-out-box2 m-auto">
            <hr />
          </div>
          <h6 className="pt-3">Our expertise spans a range of disciplines empowering businesses to optimize their operations and drive digital transformation.</h6>
        </div>
      </div>

      <div className="row px-lg-5 p-3 pb-lg-5 " id="domains" style={{ backgroundColor: '#eeeeee' }}>
       
       <div className="col-12 col-lg-3 cursor-pointer px-0"
          onClick={() => navigate('/Healthcare?ScreenName=Healthcare')}>
          <img src="Assets/Images/Domain/domain1-01.png" alt="Image not found" />
        </div>

        <div className="col-12 col-lg-3 cursor-pointer px-0"
          onClick={() => navigate('/E_Commerce?ScreenName=E-Commerce')}>
          <img src="Assets/Images/Domain/domain1-07.png" alt="Image not found" />
        </div>

        <div className="col-12 col-lg-3 cursor-pointer px-0"
          onClick={() => navigate('/FinancialServices?ScreenName=Financial Services')}>
          <img src="Assets/Images/Domain/domain1-03.png" alt="Image not found" />
        </div>

        <div className="col-12 col-lg-3 cursor-pointer px-0"
          onClick={() => navigate('/HRMS?ScreenName=Human Resource Management System')}>
          <img src="Assets/Images/Domain/domain1-02.png" alt="Image not found" />
        </div>

        <div className="col-12 col-lg-3 cursor-pointer px-0"
          onClick={() => navigate('/Agriculture?ScreenName=Agriculture')}>
          <img src="Assets/Images/Domain/domain1-04.png" alt="Image not found" />
        </div>

        <div className="col-12 col-lg-3 cursor-pointer px-0"
          onClick={() => navigate('/ManufacturingAndServicesIndustry?ScreenName=Manufacturing and Services Industry')}>
          <img src="Assets/Images/Domain/domain1-05.png" alt="Image not found" />
        </div>

        <div className="col-12 col-lg-3 cursor-pointer px-0"
          onClick={() => navigate('/Egovernance?ScreenName=E-Governance')}>
          <img src="Assets/Images/Domain/domain1-06.png" alt="Image not found" />
        </div>


      </div>

      {/* <div className="row px-lg-5 p-3 pb-lg-5" style={{ backgroundColor: '#eeeeee' }}>
        <div className="col-12 col-lg-3 cursor-pointer" style={{ backgroundColor: '#00576f' }}
          onClick={() => navigate('/Healthcare?ScreenName=Healthcare')}>
          <div className="row pt-3">
            <div className="col-6 d-flex align-items-center">
              <h5 className="text-white">Healthcare</h5>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img src="Assets/Images/Domain/healthcares-01.png" alt="Image not found" />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-3 cursor-pointer" style={{ backgroundColor: '#f5ae45' }}
          onClick={() => navigate('/HRMS?ScreenName=Human Resource Management System')}>
          <div className="row pt-3">
            <div className="col-5 d-flex justify-content-center align-items-center">
              <h5 className="text-white">HRMS</h5>
            </div>
            <div className="col-7 d-flex justify-content-center align-items-center">
              <img src="Assets/Images/Domain/hrms.png" alt="Image not found" style={{ height: "30vh" }} />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-3 cursor-pointer" style={{ backgroundColor: '#ff8372' }}
          onClick={() => navigate('/FinancialServices?ScreenName=Financial Services')}>
          <div className="row pt-3">
            <div className="col-5 d-flex justify-content-center align-items-center">
              <h5 className="text-white">Financial Services </h5>
            </div>
            <div className="col-7 d-flex justify-content-center align-items-center">
              <img src="Assets/Images/Domain/fianances-01.png" alt="Image not found" style={{ height: "30vh" }} />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-3 cursor-pointer" style={{ backgroundColor: '#5794d9' }}
          onClick={() => navigate('/Agriculture?ScreenName=Agriculture')}>
          <div className="row pt-3">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <h5 className="text-white">Water & Agriculture</h5>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img src="Assets/Images/Domain/agri-01.png" alt="Image not found" style={{ height: "30vh" }} />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-4 cursor-pointer" style={{ backgroundColor: '#666699' }}
          onClick={() => navigate('/ManufacturingAndServicesIndustry?ScreenName=Manufacturing and Services Industry')}>
          <div className="row py-3">
            <div className="col-5 d-flex justify-content-center align-items-center">
              <h5 className="text-white">Manufacturing & Service Industry</h5>
            </div>
            <div className="col-7 d-flex justify-content-center align-items-center">
              <img src="Assets/Images/Domain/ManufacturingServicesIndustry-01.png" alt="Image not found" style={{ height: "35vh" }} />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-4 cursor-pointer" style={{ backgroundColor: '#6bbfbc' }}
          onClick={() => navigate('/Egovernance?ScreenName=E-Governance')}>
          <div className="row py-3">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <h5 className="text-white">e-Governance</h5>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img src="Assets/Images/Domain/ManufacturingServicesIndustry-01.png" alt="Image not found" style={{ height: "30vh" }} />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-4 cursor-pointer" style={{ backgroundColor: '#009cb3' }}
          onClick={() => navigate('/E_Commerce?ScreenName=E-Commerce')}>
          <div className="row py-3">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <h5 className="text-white">E-Commerce</h5>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img src="Assets/Images/Domain/e-commerce-01.png" alt="Image not found" style={{ height: "30vh" }} />
            </div>
          </div>
        </div>


      </div> */}
      {/* <div className="col-12 col-md-4 col-lg-4 mt-3  ">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              Healthcare</div>
            <div className="testimonial_arrow"></div>
            <div className="px-0 pb-1">
              <p className="border-bottom py-3">National Health Mission</p>
              <p className=" py-2">Pharma</p>
            </div>
          </div>

        </div>

        <div className="col-12 col-md-4 col-lg-4 mt-3 ">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              HRMS</div>
            <div className="testimonial_arrow"></div>
            <div className="pb-1">
              <p className="border-bottom py-3">Zilla Parishad</p>
              <p className=" py-2">Municipal Corporation</p>
            </div>
          </div>

        </div>

        <div className="col-12 col-md-4 col-lg-4 mt-3 ">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              E-Commerce</div>
            <div className="testimonial_arrow"></div>
            <div className=" pb-1">
              <p className="border-bottom py-3">Agriculture</p>
              <p className=" py-2">Women's Self-Help Groups</p>
            </div>
          </div>

        </div>

        <div className="col-12 col-md-4 col-lg-4 mt-3 mt-lg-5">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              Water & Agriculture</div>
            <div className="testimonial_arrow"></div>
            <div className="px-5 pb-1">
              <p className="border-bottom py-3">Soil & Water Harvesting </p>
              <p className=" py-2">Farmer Producer Company</p>
            </div>
          </div>

        </div>

        <div className="col-12 col-md-4 col-lg-4 mt-3 mt-lg-5">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain ">
              Manufacturing & Service Industry</div>
            <div className="testimonial_arrow"></div>
            <div className="px-5 pb-1">
              <p className="border-bottom py-3">Labour Services</p>
              <p className="border-bottom py-2">Production Industry</p>
              <p className=" py-2">Service Industry</p>
            </div>
          </div>

        </div>

        <div className="col-12 col-md-4 col-lg-4 mt-3 mt-lg-5">
          <div className="border_domain mx-lg-3 bg-white">
            <div
              className="py-4 fs-5 d-flex justify-content-center align-items-center charchole text-white top_border_domain">
              e-Governance</div>
            <div className="testimonial_arrow"></div>
            <div className=" pb-1">
              <p className="border-bottom pt-3 pb-3">Muncipal Corporation</p>
              <p className="border-bottom pt-1 pb-3">Zilla Parishad </p>
              <p className="border-bottom pt-1 pb-3">Police Department</p>
              <p className="py-2">State Department</p>
            </div>
          </div>

        </div> */}

    </>
  )
}