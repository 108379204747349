export const AllReviews = () => {
  return (
    <div className="row py-5" id="quote">
      <div className="col-12 col-md-6 col-lg-5 mx-auto">
        <blockquote className="blockquote blockquote-custom bg-white py-5 px-3 shadow2 rounded">

          <video width="100%" height="300px" poster="Assets/Images/video/nagpur-01.PNG" controls>
            <source src="Assets/Images/video/nmc.mp4" type="video/mp4" />
          </video>

          <div className="blockquote-custom-icon bg-golden shadow-sm"><i className="fa fa-quote-left text-white"></i></div>
          <p className="mb-0 mt-2 font-italic fs-6 pt-3" style={{ textAlign: 'justify' }}>"Under tight deadlines and with high expectations, CDAT has delivered
            the Public Grievance Management System both Mobile App and Web Application suited to the requirement of
            Urban Local Body , The Citizens of City have given a overwhelming reponse , The app has seen 30k plus
            downloads and still counting in very short time , Well Done Team CDAT".</p>
          <footer className="blockquote-footer pt-4 mt-5 border-top"><b className="text-dark">Commissioner</b>
            <cite title="Source Title"> Nagpur Municipal Corporation</cite>
          </footer>
        </blockquote>
      </div>
      <div className="col-12 col-md-6 col-lg-5 mx-auto">

        <blockquote className="blockquote blockquote-custom bg-white pt-5 pb-4 px-3 shadow2 rounded">

          <iframe width="100%" height="300px" src="https://www.youtube.com/embed/pW0OdIeWrbk"
            title="Ahmednagar पोलिसांची नागरिकांसाठी &#39;ई-टपाल&#39; प्रणाली, पोलीस अधीक्षक Manoj Patil यांची संकल्पना |Police"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>

          <div className="blockquote-custom-icon bg-golden shadow-sm "><i className="fa fa-quote-left text-white"></i></div>
          <p className="mb-0 mt-2 font-italic fs-6 pt-3" style={{ textAlign: 'justify' }}>
            E Tapal Police Department is a internet & intranet base solution for all government functionaries to monitor their Daily Branch, Section and Table wise letter and file movement of their own Office. LFMS is helping for electronic letter inwards, letter movement from one desk to another desk, creation of desk wise files and letter draft for approval, dispatch of letters, desk wise setting due dates and reminders, desk wise queries and report and migration of letters and files from one desk to another desk.

          </p>

          <footer className="blockquote-footer pt-4 mt-lg-4 border-top"><b className="text-dark">Superintendent of Police</b>
            <cite title="Source Title"> District Ahmednagar</cite>
          </footer>

        </blockquote>

      </div>

      <div className="col-12 col-md-6 col-lg-5 mx-auto mt-5">
        <blockquote className="blockquote blockquote-custom bg-white py-5 px-3 shadow2 rounded">
          <video width="100%" height="300px" poster="Assets/Images/video/cm-01.jpg" controls>
            <source src="Assets/Images/video/amchi mulgi.mp4" type="video/mp4" />
          </video>
          <div className="blockquote-custom-icon bg-golden shadow-sm">
            <i className="fa fa-quote-left text-white"></i></div>
          <p className="mb-0 mt-2 font-italic pt-3 fs-6" style={{ textAlign: 'justify' }}> "AMCHI MULGI" Project of the State Health Department has been successfully inaugurated by the Hon. Chief Minister, Maharashtra State.
            The software under the PCPNTD Act – "AMCHI MULGI." This software aims to create a public portal for managing  grievances under the PCPNDT Act, contributing to the state's efforts to address gender-based inequalities
            and promote equity.</p>
        </blockquote>
        {/* <!-- END --> */}
      </div>

      <div className="col-12 col-md-6 col-lg-5 mx-auto mt-5">
        <blockquote className="blockquote blockquote-custom bg-white py-5 px-3 shadow2 rounded">

          <img src="Assets/Images/video/nhm.jpg" alt="" width="100%" height="300px" />


          <div className="blockquote-custom-icon bg-golden shadow-sm"><i className="fa fa-quote-left text-white"></i></div>
          <p className="pb-lg-5 mt-2 mb-lg-5 font-italic pt-3 fs-6" style={{ textAlign: 'justify' }}>We take immense pride in announcing that the Statewide Nursing Home
            Registration Project of our State Health Department was inaugurated by the Honorable Minister. </p>

        </blockquote>
        {/* <!-- END --> */}
      </div>


      <div className="col-12 col-md-6 col-lg-5 mx-auto mt-5">

        <blockquote className="blockquote blockquote-custom bg-white pt-5 pb-4 px-3 shadow2 rounded">
          <div className="blockquote-custom-icon bg-golden shadow-sm"><i className="fa fa-quote-left text-white"></i></div>
          <p className="mb-0 mt-2 font-italic pt-3 fs-6 mb-5" style={{ textAlign: 'justify' }}>The Pension Management system by CDAT was really the need of the Hour, Thanks
            Team CDAT, The pensioners of Zilla Parishad are truly benefited by this system,
            It has brought complete transparency and efficiency in the pension sanction and
            disbursement process of Zilla Parishad.</p>
          <footer className="blockquote-footer pt-4 mt-4 border-top "><b className="text-dark">Dy CAFO</b>
            <cite title="Source Title"> Zilla Parishad</cite>
          </footer>

        </blockquote>

      </div>

      <div className="col-12 col-md-6 col-lg-5 mx-auto mt-5">

        <blockquote className="blockquote blockquote-custom bg-white pt-5 pb-4 px-3 shadow2 rounded">
          <div className="blockquote-custom-icon bg-golden shadow-sm"><i className="fa fa-quote-left text-white"></i></div>
          <p className="mb-0 mt-2 font-italic fs-6" style={{ textAlign: 'justify' }}>"The Letter and File Management System designed and Developed by CDAT
            has really brought transparency in the Government Domain , The software is very light , easy to operate with
            interactive dashboards, CDAT is a company who understands the government domain, Their expertise empower the
            digital transformation in Government Offices smoothly and efficiently, Good Job Team CDAT".
          </p>
          <footer className="blockquote-footer pt-4 mt-4 border-top"><b className="text-dark">Project Director</b>
            <cite title="Source Title "> State Agriculture Department </cite>
          </footer>

        </blockquote>

      </div>

      <div className="col-12 col-md-6 col-lg-5 mx-auto mt-5">

        <blockquote className="blockquote blockquote-custom bg-white py-5 px-3 shadow2 rounded">
          <div className="blockquote-custom-icon bg-golden shadow-sm"><i className="fa fa-quote-left text-white"></i></div>
          <p className="mb-0 mt-2 font-italic fs-6" style={{ textAlign: 'justify' }}>We have taken CDAT Pune's Fertilizer Manufacturing Company ERP software. This software is very beneficial for the company. We can manage the entire company on this one software. This software helped us a lot for our company development. It helped us to track and monitor our employee's work in very efficient way. Thank you...!!
          </p>
          <footer className="blockquote-footer pt-4 mt-4 border-top"><b className="text-dark">Owner</b>
            <cite title="Source Title "> Sunrich Agro Industries </cite>
          </footer>

        </blockquote>

      </div>

      <div className="col-12 col-md-6 col-lg-5 mx-auto mt-5">

        <blockquote className="blockquote blockquote-custom bg-white py-5 px-3 shadow2 rounded">
          <div className="blockquote-custom-icon bg-golden shadow-sm"><i className="fa fa-quote-left text-white"></i></div>
          <p className="pb-lg-4 mt-2 font-italic fs-6" style={{ textAlign: 'justify' }}>Dealing with the team was a pleasant experience. The "MILSO" website launches smoothly because of the CDAT team members' prompt responses, communication, and cooperation. Thank you. 
          </p>
          <footer className="blockquote-footer pt-4 mt-5 border-top"><b className="text-dark"> Owner</b>
            <cite title="Source Title "> MILSO</cite>
          </footer>

        </blockquote>

      </div>
    </div>
  )
}