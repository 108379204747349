import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const Encroachment = () => {
    return (
        <>
        <Header active="Products"/>
            <HeroSection />
            {/* ----------------------------------- */}


            <div className="row bgdotimg " id="AMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Encroachment and Illegal Construction Monitoring System</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                    Traditionally, Encroachments and illegal construction authorities have struggled with efficiently tracking and managing daily complaints received. Scheduling and tracking of employee wise vehicle was difficult. The lack of an integrated system for monitoring complaints, scheduling inspections, and managing resources results in inefficiencies. Therefore, taking into consideration all these problems we have designed Encroachment and Illegal Construction Monitoring System. This system can receive the complaints through various portals like WhatsApp, SMS, Emails, Telephone and so on. Scheduling and tracking of vehicles will become easy and reliable. By centralizing various processes, the system will streamline operations, improve efficiency in handling complaints, and ensure better monitoring and enforcement of laws against illegal construction and encroachment. 
                    </p>

                    {/* <p>
                        It provides a comprehensive platform for daily employee performance tracking, allowing recording of achievements and punishments. Timely bulletin updates are facilitated within predefined schedules. With a user-friendly interface accessible on various devices, it's an invaluable tool for efficient administrative tasks, offering precise financial year-based data segmentation for reporting and performance analysis.
                    </p> */}
                </div>
            </div>
            {/* <!--end of AMS sopi--> */}


            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Facility to import public complaints through various public portals </b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Vehicle Tracking on daily basis</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Define ward & employee wise vehicle scheduling & mapping</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Officials can add multiple Before and After work photos</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>	Facility to issue legal notices to the defaulter</b></p>
                    </div>
                </div>
                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>	Employee attendance</b></p>
                    </div>
                </div>

            </div>
            {/* <!--end of row key features--> */}


            {/* <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/ASSET_MANAGEMENT_SYSTEM.pdf')} 
                      className="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div> */}

            {/* <!--end of Brochure row--> */}


            {/* --------------------------------- */}
            <Footer />
        </>
    )
}