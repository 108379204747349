import { useNavigate } from "react-router-dom"
import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const Products = () => {
    const navigate = useNavigate()
    return (
        <>
            <Header active="Products" />
            <HeroSection />

            {/* --------------------- */}


            <div className="row py-5 px-lg-4">

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/AssetManagementSystem?ScreenName=IT (Asset) Management System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 asset_product producthover">
                            <span>
                                <span>
                                    <span>

                                        <img src="Assets/Images/Products/all_pc-06.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">IT (Asset) Management System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Encroachment?ScreenName=Encroachment and Illegal Construction Monitoring System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 Encroachment_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/all_pc-02.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Encroachment and Illegal Construction Monitoring System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/GPF?ScreenName=Group Provident Fund (GPF)&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 GPF_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/all_pc-08.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Group Provident Fund (GPF)</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/')} >
                        <div className="productheight border p-3 py-lg-3 Marine_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/all_pc-03.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Marine Insurance Learning and Sharing Portal</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/TreeRiskAssessmentSystem?ScreenName=Tree Risk Assessment System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 TRASS_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/all_pc-05.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">TRASS - Tree Risk Assessment System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/GGCI?ScreenName=Global Green Credit Initiative Government of India&Name-Products')} >
                        <div className="productheight border p-3 py-lg-3 ggci_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/all_pc-04.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Global Green Credit Initiative Government of India</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/RubberPlantationMonitoringSystem?ScreenName=Rubber Plantation Monitoring System for Rubber Board&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 rubber_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/rubber_board-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Rubber Plantation Monitoring System for Rubber Board</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/TreeMonitoringSystem?ScreenName=Tree Monitoring System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 tms_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/all_pc-07.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Tree Monitoring System (Tree Inventory, Tree Geo-Tagging, Tree Cutting Tree Trimming, Tree Transplanting) </h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Sopi?ScreenName=System of Pension Illustration&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 Pension_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/sopi-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">System of Pension Illustration (SOPI) </h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Ams?ScreenName=Asset Management System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 ams_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/ams-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Asset Management System (AMS)</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Mrms?ScreenName=Medical Reimbursement Management System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 mrms_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/mrms-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Medical Reimbursement Management System (MRMS)</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Mrms?ScreenName=Medical Reimbursement Management System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 medicine_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/medicineinventory-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Medicine Stock Management System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Lfms?ScreenName=Letter and File Management System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 LFMS_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/lfms-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Letter & File Management System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/PoliceGazette?ScreenName=Police Gazette Management System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 Gazette_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/gazzet-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Police Gazette Management System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/NursingHome?ScreenName=Nursing Home Registration Application&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 Nursing_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/Nursing_home-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Nursing Home Registration System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/HRMSystem?ScreenName=Human Resource Management System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 hrdms_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/hrdms-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Human Resource Management System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/AgricultureERP?ScreenName=Agriculture Fertilizer Manufacturing  Marketing ERP&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 Agriculture_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/AgricultureAndManufacturingIndustryERP-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">ERP System for Fertilizer Manufacturing Company (FMC)</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/SmartLaborApplication?ScreenName=Smart Labor Application&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 placewell_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/placewell-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Smart Labor Application</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/ERP?ScreenName=Manufacturing and  Service  ERP&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 samarth_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/ManufacturingIndustryERP-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">ERP System for Manufacturing and Service Industry </h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/GlobalKisanMarket?ScreenName=Global Kisan market  ICT  Model&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 gkmarket_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/gkmarket-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Global Kisan Market</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Etapal?ScreenName=Online Letter System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 etapal_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/E-tapal-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">E-TAPAL - Online Letter / Application Management System for Police Department</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/HealthCibil?ScreenName=HEALTH CIBIL&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 Health_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/healthcare-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Health Cibil</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Garden?ScreenName=Garden Maintenance Work Monitoring System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 garden_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/garden-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Garden Maintenance Work Monitoring System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/PTMS?ScreenName=Project Tracking & Monitoring System&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 PTMS_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/ptms-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Project Tracking & Monitoring System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/AmchiMulgi?ScreenName=Public Grievance in Aamchi Mulgi Scheme&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 PublicGrievance_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/amchimulgimaha-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3"> Public Grievance In Aamchi Mulgi Scheme</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>


                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/VanPathSurakshak?ScreenName=Van Path सु-Rakshak for Forest Department&Name=Products')} >
                        <div className="productheight border p-3 py-lg-3 vanpathsurakshak_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/vanpathsurakshak-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Van Path सु-Rakshak for Forest Department</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/EmployeeGrievanceMonitoringSystem?ScreenName=Employee Grievance Monitoring System&Name=products')} >
                        {/* onClick={() => navigate('/EmployeeGrievance?ScreenName=Employee Grievance Monitoring System&Name=Products')} > */}
                        <div className="productheight border p-3 py-lg-3 EGMS_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/Employee Grievance Monitoring System-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Employee Grievance Monitoring System</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 px-3 text-center pt-3">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/RoadWork?ScreenName=Road Work&Name=products')} >
                        {/* onClick={() => navigate('/EmployeeGrievance?ScreenName=Employee Grievance Monitoring System&Name=Products')} > */}
                        <div className="productheight border p-3 py-lg-3 Road_product producthover" >
                            <span>
                                <span>
                                    <span>
                                        <img src="Assets/Images/Products/Road-01.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                    <h5 className=" rounded products_headings text-dark heights_p mt-3">Monitoring of Road (O/M) Work</h5>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

            </div>

            {/* <div className="row py-5">

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Mrms?ScreenName=Medical Reimbursement Management System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 mrms_product producthover">
                            <span>
                                <span>
                                    <span>

                                        <img src="Assets/Images/Products/all_pc-06.png" style={{ width: '100%' }} alt="" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Mrms?ScreenName=Medical Reimbursement Management System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 mrms_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Medical Reimbursement Management System (MRMS)
                                        </h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/mrms_computer-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Ams?ScreenName=Asset Management System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  ams_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Asset Management System (AMS)</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/AMShero.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>


                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5 ">
                    <a href="javascript:void(0)" onClick={() => navigate('/Sopi?ScreenName=System of Pension Illustration&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 water_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">System of Pension Illustration (SOPI)</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/pension__computer-01.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5 ">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/MedicineStockManageSystem?ScreenName=Medicine Stock Management System&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 medicine_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Medicine Stock Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/MEDICINE_INVENTORY_COMPUTER-01.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/PTMS?ScreenName=Project Tracking & Monitoring System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  ptms_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Project Tracking & Monitoring System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/PTMS.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>


                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/HealthCibil?ScreenName=HEALTH CIBIL&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 stp_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Health Cibil</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/healthcibilhero-01.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/GlobalKisanMarket?ScreenName=Global Kisan market - ICT  Model&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 gk_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Global Kisan Market</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/gk_market-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/PoliceGazette?ScreenName=Police Gazette Management System&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 gazzet_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Police Gazette Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/police_gaztte_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />

                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/AgricultureERP?ScreenName=Agriculture Fertilizer Manufacturing & Marketing ERP&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 gaytri_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Agriculture and Manufacturing Industry ERP
                                        </h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/AFM_heero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>


                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/ERP?ScreenName=Manufacturing and  Service ERP&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 samarth_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Manufacturing and Service Industry ERP</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/samarth.png" width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Lfms?ScreenName=Letter and File Management System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  mrms_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Letter & File Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/Lfms_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>



                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Etapal?ScreenName=Online Letter System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 toilet_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Online Letter System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/etapal_hero.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/HRMSystem?ScreenName=Human Resource Management System&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 hrms_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Human Resource Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/Hrms_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/SmartLaborApplication?ScreenName=Smart Labor Application&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  sewerage_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Smart Labor Application</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/smart_labor_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/NursingHome?ScreenName=Nursing Home Registration Application&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 stp_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Nursing Home Registration System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/nursing_home.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/RoadWork?ScreenName=Monitoring of Road (O/M) Work&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 water_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of Road (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/Road_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Sewerage?ScreenName=Monitoring of Sewerage (O/M) Work&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5  gaytri_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of Sewerage (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/swerage_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>



                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/Garden?ScreenName=Monitoring of Garden (O/M) Work&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 gazzet_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of Garden (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/garden_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/WaterSupply?ScreenName=Monitoring of Water Supply (O/M) Work&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5  stp_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of Water Supply (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/water_hero-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>

                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/STP?ScreenName=Monitoring of STP (O/M) Work&Name=Products')}>
                        <div className="productheight border p-3 p-lg-5  medicine_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Monitoring of STP (O/M) Work</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/stp_product-01.png" width="100%" alt="" srcset=""
                                            className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>



                <div className="col-12 col-lg-4 py-0 px-3 px-lg-5">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/PublicGrievance?ScreenName=Public Grievance Management System&Name=Products')} >
                        <div className="productheight border p-3 p-lg-5 mt-lg-5 toilet_product producthover">
                            <span>
                                <span>
                                    <span>
                                        <h4 className="text-white pb-2 blacktext">Public Grievance Management System</h4>
                                        <span className="btnpention">Read More</span>
                                        <img src="Assets/Images/hero_section/public_grievance_management_system_screen_hero.png"
                                            width="100%" alt="" srcset="" className="py-4" />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </a>
                </div>




            </div> */}

            {/* --------------------- */}
            <Footer />
        </>
    )

}