export const Diwali = () => {
    return (
        <>

            <div className="row p-5" id="event_photo_four">

                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Diwali</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3 text-center">
                        <div className="">
                            <a href="Assets/Images/event/diwali-01 (2).jpg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/diwali-01 (2).jpg" alt="" />
                                </span>
                            </a>
                        </div>
                        {/* <span className="yearwiseend">2024</span> */}
                    </div>
                </div>

                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3 text-center">
                        {/* <span className="yearwise">2023?</span> */}
                        <div>
                            <a href="Assets/Images/event/diwali-01.jpg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/diwali-01.jpg " alt="" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3 text-center">
                        {/* <span className="yearwise">2022</span> */}
                        <div>
                            <a href="Assets/Images/event/diwali2.jpg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/diwali2.jpg" alt="" />
                                </span>
                            </a>
                        </div>

                    </div>
                </div>


                <div className=" col-12 col-md-6 col-lg-3 ds">
                    <div className="gallery-item mb-3 text-center">
                        <div>
                            <a href="Assets/Images/event/diwali4-01-min (1).jpg" className="thumbnail">
                                <span>
                                    <img src="Assets/Images/event/diwali4-01-min (1).jpg" alt="" />
                                </span>
                            </a>
                        </div>
                        {/* <span className="yearwiseend">2023</span> */}
                    </div>
                </div>

            </div>
        </>
    )
}