import { Footer } from "../Footer"
import { Header } from "../Header"
import { AboutPage } from "./AboutPage"
import { CallToAction } from "./CallToAction"
import { ClientSays } from "./ClientSays"
import { Clients } from "./Clients"
import { ConnectMap } from "./ConnectMap"
import { CoreValue } from "./CoreValue"
import { CounterUp } from "./CounterUp"
import { DomainWeWork } from "./DomainWeWork"
import { FEATURED_PORTFOLIO } from "./FEATURED_PORTFOLIO"
import { GetIntouchForm } from "./GetIntouchForm"
import { HeroSection } from "./HeroSection"
import { Ideas } from "./Ideas"
import { OurServices } from "./OurServices"
import { Partner } from "./Partner"
import { Technology } from "./Technology"

export const MainHomePage = () => {
    return (
        <>
            <Header active="Home" />
            <HeroSection />
            <AboutPage />
            <Clients />
            <CoreValue />
            {/* <CounterUp /> */}
            <OurServices />
            {/* <CallToAction /> */}
            <DomainWeWork />
            <Partner />
            {/* <FEATURED_PORTFOLIO /> */}
            <Technology />
            {/* <Ideas /> */}
            <ClientSays />
            <ConnectMap />
            {/* <GetIntouchForm /> */}
            <Footer />
        </>
    )
}