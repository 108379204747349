export const Vacancy = () => {
    return (
        <>
            <div className="row px-2 px-lg-5 pt-4" id="vaccancy">

                <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/web.png" className="card-img-top text-center" alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>ASP .NET Developer</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark mb-3">
                                        <ul className="px-3 py-0 my-0" style={{ height: '230px' }}>
                                            <li>C#.Net, ASP.Net, Web API, Entity Framework, Java script, HTML, CSS, SQL
                                                Server 2014 or above.</li>
                                            <li>Candidate has to develop Web Application using following <span className="fw-bold">
                                                Technologies:</span>
                                                ASP.NET with .NET 4.5, Entity Framework, .Net Framework Ajax / JQUERY, C#,
                                                SQL SERVER, Bootstrap.</li>


                                            <li> <span className="fw-bold">Database: </span>SQL Server 2014 or Above.</li>

                                        </ul>
                                        <span className="read-more-target  ps-2">
                                            <ul className="px-3 py-0 my-0">
                                                <li><span className="fw-bold">Tools:</span> Visual Studio 2015, Team Foundation
                                                    Server, SQL Server Management Studio.</li>
                                                <li>Overall experience of 2 to 3 years in .NET framework, ASP.NET.</li>
                                                <li>.NET developer with good knowledge of C#, ASP.NET, Entity Framework, HTML,
                                                    CSS, Ajax, JQUERY, SQL SERVER, Bootstrap.</li> <br />
                                            </ul>
                                            <span className="py-3"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className="ps-3 pt-2">
                                                <li>Participate in requirements analysis.</li>
                                                <li>Collaborate with internal teams to produce software design and architecture.
                                                </li>
                                                <li>Write clean, scalable code using .NET programming languages.</li>
                                                <li>Test and deploy applications and systems.</li>
                                                <li>Revise, update, refactor and debug code.</li>
                                                <li>Improve existing software.</li>
                                                <li>Develop documentation throughout the software development life cycle (SDLC).
                                                </li>
                                                <li>Serve as an expert on applications and provide technical support.</li> <br />
                                            </ul>
                                            <div>
                                                <b>Experience:</b>
                                                <p> Min. 1+ Experience (Fresher's can also apply)</p>
                                            </div>

                                            <a href="#forminput"
                                                className="py-1 mb-3 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more" className="read-more-trigger_closed  servicefont pointer">Read More...</label>
                                <label for="read-more" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/web.png" className="card-img-top text-center" alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Full Stack React JS / Node JS</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more3" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark mb-3">
                                        <ul className="px-3 py-0 my-0" style={{ height: '230px' }}>
                                            <li>Must have Minimum of 2+ Years of hands-on experience in programming with
                                                Node.Js and experience in ReactJS.</li>
                                            <li>Experience in working with backend frameworks like Express.js.</li>
                                            <li>Hands on experience in building REST-based APIs.</li>
                                            <li>Hands on experience with JavaScript Development on both client &
                                                server-side.</li>
                                        </ul>
                                        <span className="read-more-target  ps-2">
                                            <ul className="px-3 py-0 mb-3">
                                                <li>Hands on experience on authentication & authorization like oauth2 , JWT.
                                                </li>
                                                <li>In-depth knowledge & Proficiency in SQL and/or non-relational databases such
                                                    as SQL/NO-SQL, Node JS, and React JS.</li>
                                                <li> Develop and maintain scalable user interfaces using ReactJS.</li>
                                                <li>Experience with modern frameworks and design patterns.</li>
                                                <li>Experience in micro services, cloud applications using AWS or any other
                                                    cloud.</li>
                                                <li> Monitoring and alerting of the applications.</li>
                                                <li> Experience with modern frameworks and design patterns. </li>
                                                <li> Experience in Version control system like GIT.</li>
                                                <li> Experience of building applications handling non-functional requirements,
                                                    scalability, Availability, Resilience, Performance etc.</li>
                                                <li> Aware of CI/CD pipelines, builds, deployments etc.</li>
                                                <li> Experience of design and architecture of the application.</li>

                                            </ul>
                                            <span className="py-3"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className="ps-3 pt-2">
                                                <li>Developing and maintaining all server-side network components.</li>
                                                <li>Ensuring optimal performance of the central database and responsiveness to
                                                    front-end requests.</li>
                                                <li>Collaborating with front-end developers on the integration of elements.</li>
                                                <li>Designing customer-facing UI and back-end services for various business
                                                    processes.</li>
                                                <li>Developing high-performance applications by writing testable, reusable, and
                                                    efficient code.</li>
                                                <li>Implementing effective security protocols, data protection measures, and
                                                    storage solutions.</li>
                                                <li>Running diagnostic tests, repairing defects, and providing technical
                                                    support.</li>
                                                <li>Documenting Node.js processes, including database schemas, as well as
                                                    preparing reports.</li>
                                                <li>Recommending and implementing improvements to processes and technologies.
                                                </li>
                                                <li>Keeping informed of advancements in the field of Node.js development.</li>
                                            </ul>
                                            <div>
                                                <b>Experience:</b>
                                                <p> Min. 1+ Experience (Fresher's can also apply)</p>
                                            </div>
                                            <a href="#forminput"
                                                className="py-1 mb-3 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more3" className="read-more-trigger_closed  servicefont pointer">Read More...</label>
                                <label for="read-more3" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/full-stack.png" className="card-img-top text-center" alt="img not found"
                                style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Full Stack React JS / Node JS</b></span>

                            <p className="p1 py-1 mb-0">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more3" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark">
                                        <ul className="ps-3 my-0" style={{ height: '230px' }}>
                                            <li>Must have Minimum of 2+ Years of hands-on experience in programming with
                                                Node.Js and experience in ReactJS.</li>
                                            <li>Experience in working with backend frameworks like Express.js.</li>
                                            <li>Hands on experience in building REST-based APIs.</li>
                                            <li>Hands on experience with JavaScript Development on both client &
                                                server-side.</li>
                                        </ul>
                                        <span className="read-more-target ps-3">
                                            <ul className="px-3 py-0 my-0">
                                                <li>Hands on experience on authentication & authorization like oauth2 , JWT.
                                                </li>
                                                <li>In-depth knowledge & Proficiency in SQL and/or non-relational databases such
                                                    as SQL/NO-SQL, Node JS, and React JS.</li>
                                                <li> Develop and maintain scalable user interfaces using ReactJS.</li>
                                                <li>Experience with modern frameworks and design patterns.</li>
                                                <li>Experience in micro services, cloud applications using AWS or any other
                                                    cloud.</li>
                                                <li> Monitoring and alerting of the applications.</li>
                                                <li> Experience with modern frameworks and design patterns. </li>
                                                <li> Experience in Version control system like GIT.</li>
                                                <li> Experience of building applications handling non-functional requirements,
                                                    scalability, Availability, Resilience, Performance etc.</li>
                                                <li> Aware of CI/CD pipelines, builds, deployments etc.</li>
                                                <li> Experience of design and architecture of the application.</li>
                                           
                                            </ul>
                                            <br />
                                            <span className="py-3"><b>Roles & Responsibility:</b></span> <br />

                                            <ul className="ps-4 pt-2">
                                                <li>Developing and maintaining all server-side network components.</li>
                                                <li>Ensuring optimal performance of the central database and responsiveness to
                                                    front-end requests.</li>
                                                <li>Collaborating with front-end developers on the integration of elements.</li>
                                                <li>Designing customer-facing UI and back-end services for various business
                                                    processes.</li>
                                                <li>Developing high-performance applications by writing testable, reusable, and
                                                    efficient code.</li>
                                                <li>Implementing effective security protocols, data protection measures, and
                                                    storage solutions.</li>
                                                <li>Running diagnostic tests, repairing defects, and providing technical
                                                    support.</li>
                                                <li>Documenting Node.js processes, including database schemas, as well as
                                                    preparing reports.</li>
                                                <li>Recommending and implementing improvements to processes and technologies.
                                                </li>
                                                <li>Keeping informed of advancements in the field of Node.js development.</li>
                                                <div> <b>Experience:</b></div>
                                                <span> Min. 1+ Experience (Fresher's can also apply)</span>
                                            </ul>
                                            <a href="#forminput"
                                                className="py-1 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more3" className="read-more-trigger_closed  0.
                                servicefont pointer">Read More...</label>
                                <label for="read-more3" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            </p>
                        </div>
                    </div>
                </div> */}

                <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/web.png" className="card-img-top text-center" alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Application Support Engineer</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more4" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark mb-3">
                                        <ul className="px-3 py-0 my-0" style={{ height: '230px' }}>
                                            <li>Providing software application support under the supervision of the Senior
                                                Engineer.</li>
                                            <li>Performing analyses on software application functionality and suggesting
                                                improvements.</li>

                                            <li>Consulting with the software development team, internal users, and clients
                                                to improve application performance.</li>

                                        </ul>
                                        <span className="read-more-target  ps-2">
                                            <ul className="px-3 py-0 my-0">
                                                <li>Ensuring effective front-end and back-end functionality of applications.
                                                </li>
                                                <li>Managing code migration across environments to ensure continued and
                                                    synchronized functionality.</li>
                                                <li>Establishing the root causes of application errors, and escalating serious
                                                    concerns to the Senior Engineer.</li>
                                                <li>Keeping a record of configuration changes and scheduling application
                                                    updates.</li>
                                                <li>Documenting processes and monitoring application performance metrics.</li>
                                                <li>Providing front-end support to clients and colleagues in other departments.
                                                </li> <br />
                                            </ul>
                                            <span className="py-3"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className="ps-3 pt-2">
                                                <li>A bachelor's degree in software engineering, computer science, information
                                                    technology, information systems, computer engineering, or similar.</li>
                                                <li>Demonstrable experience as an application support engineer in a related
                                                    field.</li>
                                                <li>Advanced knowledge of front-end and back-end programming languages, such as
                                                    C++, Javascript, Python, and Ruby.</li>
                                                <li>Ability to manage code migration, document configuration changes, and
                                                    monitor performance.</li>
                                                <li>Exceptional ability to provide front-end support to internal departments and
                                                    web-based clients.</li>
                                                <li>Advanced proficiency in determining the causes of application errors and
                                                    repairing them.</li>
                                                <li>Knowledge of Advanced Encryption Standards (AES).</li>
                                                <li>Ability to keep up with innovation in application design.</li>
                                                <li>Exceptional communication skills.</li>
                                            </ul>
                                            <div>
                                                <b>Experience:</b>
                                                <p> Min. 1+ Experience (Fresher's can also apply)</p>
                                            </div>

                                            <a href="#forminput"
                                                className="py-1 mb-3 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more4" className="read-more-trigger_closed  servicefont pointer">Read More...</label>
                                <label for="read-more4" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/Application_Support_Engineer.png" className="card-img-top text-center"
                                alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Application Support Engineer</b></span>

                            <p className="p1 py-1 mb-0">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more4" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark mb-3">
                                        <ul className="ps-3 mb-0">
                                            <li>Providing software application support under the supervision of the Senior
                                                Engineer.</li>
                                            <li>Performing analyses on software application functionality and suggesting
                                                improvements.</li>

                                            <li>Consulting with the software development team, internal users, and clients
                                                to improve application performance.</li>
                                        </ul>
                                        <span className="read-more-target ps-3">
                                            <ul className="px-3 py-1 mb-0">
                                                <li>Ensuring effective front-end and back-end functionality of applications.
                                                </li>
                                                <li>Managing code migration across environments to ensure continued and
                                                    synchronized functionality.</li>
                                                <li>Establishing the root causes of application errors, and escalating serious
                                                    concerns to the Senior Engineer.</li>
                                                <li>Keeping a record of configuration changes and scheduling application
                                                    updates.</li>
                                                <li>Documenting processes and monitoring application performance metrics.</li>
                                                <li>Providing front-end support to clients and colleagues in other departments.
                                                </li>
                                            </ul>
                                            <br />

                                            <span className="py-3"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className=" pt-2 ps-3">
                                                <li>A bachelor's degree in software engineering, computer science, information
                                                    technology, information systems, computer engineering, or similar.</li>
                                                <li>Demonstrable experience as an application support engineer in a related
                                                    field.</li>
                                                A<li>dvanced knowledge of front-end and back-end programming languages, such as
                                                    C++, Javascript, Python, and Ruby.</li>
                                                <li>Ability to manage code migration, document configuration changes, and
                                                    monitor performance.</li>
                                                <li>Exceptional ability to provide front-end support to internal departments and
                                                    web-based clients.</li>
                                                <li>Advanced proficiency in determining the causes of application errors and
                                                    repairing them.</li>
                                                <li>Knowledge of Advanced Encryption Standards (AES).</li>
                                                <li>Ability to keep up with innovation in application design.</li>
                                                <li>Exceptional communication skills.</li>
                                                <div> <b>Experience:</b></div>
                                                <span> Min. 1+ Experience (Fresher's can also apply)</span>
                                            </ul> <br />


                                            <a href="#forminput"
                                                className="py-1 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more4" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                                <label for="read-more4" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            </p>

                        </div>
                    </div>
                </div> */}

                <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/web.png" className="card-img-top text-center" alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Business Analyst</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more5" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark mb-3">
                                        <ul className="px-3 py-0 my-0" style={{ height: '230px' }}>
                                            <li>Creating a detailed business analysis, outlining problems, opportunities and
                                                solutions for a business.</li>
                                            <li>Budgeting and forecasting.</li>
                                            <li>Planning and monitoring.</li>
                                            <li>Variance Analysis.</li>
                                            <li>Pricing.</li>
                                            <li>Reporting.</li>
                                            <li>Defining business requirements and reporting them back to stakeholders.</li>

                                        </ul>
                                        <span className="read-more-target  ps-2">
                                            <ul className="px-3 py-0 my-0">
                                                <li>Able to exercise independent judgment and take action on it.</li>
                                                <li>Excellent analytical, mathematical, and creative problem-solving skills.
                                                </li>
                                                <li>Excellent listening, interpersonal, written, and oral communication skills.
                                                </li>
                                                <li>Logical and efficient, with keen attention to detail.</li>
                                                <li>Highly self-motivated and directed.</li>
                                                <li>Ability to effectively prioritize and execute tasks while under pressure.
                                                </li>
                                                <li>Strong customer service orientation.</li>
                                                <li>Experience working in a team-oriented, collaborative environment.</li> <br />

                                            </ul>

                                            <div>
                                                <b>Experience:</b>
                                                <p> Min. 1+ Experience (Fresher's can also apply)</p>
                                            </div>

                                            <a href="#forminput"
                                                className="py-1 mb-3 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more5" className="read-more-trigger_closed  servicefont pointer">Read More...</label>
                                <label for="read-more5" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/Business_Analyst.png" className="card-img-top text-center" alt="img not found"
                                style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Business Analyst</b></span>

                            <p className="p1 py-1 mb-0">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more55" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark">
                                        <ul className="ps-3 my-0">
                                            <li>Creating a detailed business analysis, outlining problems, opportunities and
                                                solutions for a business.</li>
                                            <li>Budgeting and forecasting.</li>
                                            <li>Planning and monitoring.</li>
                                            <li>Variance Analysis.</li>
                                            <li>Pricing.</li>
                                            <li>Reporting.</li>
                                        </ul>
                                        <span className="read-more-target ps-3">

                                            <ul className="px-3 py-0 my-0">
                                                <li>Defining business requirements and reporting them back to stakeholders.</li>
                                                <li>Able to exercise independent judgment and take action on it.</li>
                                                <li>Excellent analytical, mathematical, and creative problem-solving skills.
                                                </li>
                                                <li>Excellent listening, interpersonal, written, and oral communication skills.
                                                </li>
                                                <li>Logical and efficient, with keen attention to detail.</li>
                                                <li>Highly self-motivated and directed.</li>
                                                <li>Ability to effectively prioritize and execute tasks while under pressure.
                                                </li>
                                                <li>Strong customer service orientation.</li>
                                                <li>Experience working in a team-oriented, collaborative environment.</li> <br />
                                            </ul>
                                            <div> <b>Experience:</b></div>
                                            <span className="mb-3"> Min. 1+ Experience (Fresher's can also apply)</span>

                                            <a href="#forminput"
                                                className="py-1 mt-2 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more55" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                                <label for="read-more55" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            </p>

                        </div>
                    </div>
                </div> */}

                <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/web.png" className="card-img-top text-center" alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Business Development Manager</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more6" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark mb-3">
                                        <ul className="px-3 py-0 my-0" style={{ height: '230px' }}>
                                            <li>Identify potential clients in the target market and complete appropriate
                                                research on the prospective client’s business and equipment needs.</li>
                                            <li>Develop relationships with prospective clients, while maintaining existing
                                                client relationships.</li>
                                            <li>Negotiate contract terms with clients and communicate terms to stakeholders.
                                            </li>
                                        </ul>
                                        <span className="read-more-target  ps-2">
                                            <ul className="px-3 py-0 mb-3">
                                                <li>Partner with sales teams to create contract-winning proposals for current and
                                                    prospective clients.</li>

                                                <li>Collaborate with design and production teams to ensure contracted product
                                                    specifications are executed on-time and as agreed.</li>
                                                <li>Become a subject matter expert on our business products, processes and
                                                    operations, and remain up-to-date on industry news.</li>
                                            </ul>
                                            <span className="py-3"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className="ps-3 pt-2">
                                                <li>Researching organizations and individuals online (especially on social
                                                    media) to
                                                    identify ways of reaching out to them and generate new leads and potential
                                                    new
                                                    markets.</li>
                                                <li>Must have experience in contacting potential clients via email or phone
                                                    calls to
                                                    establish rapport and set up meetings.</li>
                                                <li>Work with senior team members to identify and manage risks.</li>
                                                <li>Strong communication and IT fluency.</li>
                                                <li>Maintain fruitful relationships with clients and address their needs
                                                    effectively.</li>
                                                <li>Research and identify new market opportunities.</li>
                                                <li>Creative talents and the ability to solve tough problems.</li>
                                                <li>knowledge of the IT service industry and its current trends.</li>
                                                <li>The ability to handle pressure and meet deadlines.</li>
                                                <li>Skill in prioritizing and triaging obligations.</li>
                                                <li>Work with the team to achieve short and long term revenue and profit growth.
                                                </li>
                                                <li>Conduct market competitive analysis to develop road-map and sales strategy
                                                    to
                                                    secure new business.</li>
                                            </ul>
                                            <div>
                                                <b>Experience:</b>
                                                <p> Min. 1+ Experience (Fresher's can also apply)</p>
                                            </div>

                                            <a href="#forminput"
                                                className="py-1 mb-3 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more6" className="read-more-trigger_closed  servicefont pointer">Read More...</label>
                                <label for="read-more6" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/Business_Development_Manager.png" className="card-img-top text-center"
                                alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Business Development Manager</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more6" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark">
                                        <ul className="ps-3 my-0">
                                            <li>Identify potential clients in the target market and complete appropriate
                                                research on the prospective client’s business and equipment needs.</li>
                                            <li>Develop relationships with prospective clients, while maintaining existing
                                                client relationships.</li>

                                        </ul>
                                        <span className="read-more-target ps-3">
                                            <ul className="px-3 py-0 mt-0">
                                                <li>Partner with sales teams to create contract-
                                                    winning proposals for current and
                                                    prospective clients.</li>
                                                <li>Negotiate contract terms with clients and communicate terms to stakeholders.
                                                </li>
                                                <li>Collaborate with design and production teams to ensure contracted product
                                                    specifications are executed on-time and as agreed.</li>
                                                <li>Become a subject matter expert on our business products, processes and
                                                    operations, and remain up-to-date on industry news.</li>
                                            </ul>
                                            <span className="py-4"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className="ps-3 pt-2 my-0">
                                                <li>Researching organizations and individuals online (especially on social
                                                    media) to
                                                    identify ways of reaching out to them and generate new leads and potential
                                                    new
                                                    markets.</li>
                                                <li>Must have experience in contacting potential clients via email or phone
                                                    calls to
                                                    establish rapport and set up meetings.</li>
                                                <li>Work with senior team members to identify and manage risks.</li>
                                                <li>Strong communication and IT fluency.</li>
                                                <li>Maintain fruitful relationships with clients and address their needs
                                                    effectively.</li>
                                                <li>Research and identify new market opportunities.</li>
                                                <li>Creative talents and the ability to solve tough problems.</li>
                                                <li>knowledge of the IT service industry and its current trends.</li>
                                                <li>The ability to handle pressure and meet deadlines.</li>
                                                <li>Skill in prioritizing and triaging obligations.</li>
                                                <li>Work with the team to achieve short and long term revenue and profit growth.
                                                </li>
                                                <li>Conduct market competitive analysis to develop road-map and sales strategy
                                                    to
                                                    secure new business.</li>
                                                <div> <b>Experience:</b>
                                                </div>

                                                <span> Min. 1+ Experience (Fresher's can also apply)</span>
                                            </ul>
                                            <a href="#forminput"
                                                className="py-1 mt-2 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more6" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                                <label for="read-more6" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            </p>

                        </div>
                    </div>
                </div> */}

                <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/web.png" className="card-img-top text-center" alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>QC Engineer</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more7" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark mb-3">
                                        <ul className="px-3 py-0 my-0" style={{ height: '230px' }}>
                                            <li>Hands on manual testing.</li>
                                            <li>At least 1 years of web application testing experience.</li>
                                            <li>Hands on experience of API testing.</li>
                                            <li>Goods with writing basic SQL queries, understand Database concepts.</li>
                                            <li>Analyze and Document Project Requirements.</li>
                                            <li>Reviewing software requirements and preparing test scenarios.</li>

                                        </ul>
                                        <span className="read-more-target  ps-2">
                                            <ul className="px-3 py-0 mb-3">
                                                <li>Execute test cases (manual) and analyze results.</li>
                                                <li>Ability to document and troubleshoot errors.</li>
                                                <li>Excellent communication and critical thinking skills.</li>
                                            </ul>
                                            <span className="py-3 pt-4"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className="ps-3 pt-2">
                                                <li>Participate in requirements analysis.</li>
                                                <li>Collaborate with internal teams to produce software design and architecture.
                                                </li>
                                                <li>Write clean, scalable code using .NET programming languages.</li>
                                                <li>Test and deploy applications and systems.</li>
                                                <li>Revise, update, refactor and debug code.</li>
                                                <li>Improve existing software.</li>
                                                <li>Develop documentation throughout the software development life cycle (SDLC).
                                                </li>
                                                <li>Serve as an expert on applications and provide technical support.</li>
                                            </ul>
                                            <div>
                                                <b>Experience:</b>
                                                <p> Min. 1+ Experience (Fresher's can also apply)</p>
                                            </div>

                                            <a href="#forminput"
                                                className="py-1 mb-3 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more7" className="read-more-trigger_closed  servicefont pointer">Read More...</label>
                                <label for="read-more7" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/Quality_control_engineers.png" className="card-img-top text-center" alt="img not found"
                                style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>QC Engineer</b></span>

                            <p className="p1 py-1 mb-0">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more8" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark">
                                        <ul className="ps-3 py-0 my-0">
                                            <li>Hands on manual testing.</li>
                                            <li>At least 1 years of web application testing experience.</li>
                                            <li>Hands on experience of API testing.</li>
                                            <li>Goods with writing basic SQL queries, understand Database concepts.</li>
                                            <li>Analyze and Document Project Requirements.</li>
                                        </ul>
                                        <span className="read-more-target ps-3">
                                            <ul className="px-3 py-0 mt-0">
                                                <li>Reviewing software requirements and preparing test scenarios.</li>
                                                <li>Execute test cases (manual) and analyze results.</li>
                                                <li>Ability to document and troubleshoot errors.</li>
                                                <li>Excellent communication and critical thinking skills.</li>
                                            </ul>
                                            <span className="py-3"><b>Roles & Responsibility:</b></span>

                                            <ul className="ps-3 pt-2">
                                                <li>Participate in requirements analysis.</li>
                                                <li>Collaborate with internal teams to produce software design and architecture.
                                                </li>
                                                <li>Write clean, scalable code using .NET programming languages.</li>
                                                <li>Test and deploy applications and systems.</li>
                                                <li>Revise, update, refactor and debug code.</li>
                                                <li>Improve existing software.</li>
                                                <li>Develop documentation throughout the software development life cycle (SDLC).
                                                </li>
                                                <li>Serve as an expert on applications and provide technical support.</li>
                                                <div> <b>Experience:</b></div>
                                                <span> Min. 1+ Experience (Fresher's can also apply)</span>
                                            </ul>


                                            <a href="#forminput"
                                                className="py-1 mt-2 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more8" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                                <label for="read-more8" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            </p>

                        </div>
                    </div>
                </div> */}

                <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/web.png" className="card-img-top text-center" alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>UI/ UX Designer</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more8" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark mb-3">
                                        <ul className="px-3 py-0 my-0" style={{ height: '230px' }}>
                                            <li>Understand product specifications and user psychology.</li>
                                            <li>Conduct concept and usability testing and gather feedback.</li>
                                            <li>Create personas through user research and data.</li>
                                            <li>Define the right interaction model and evaluate its success.</li>
                                            <li>Develop wireframes and prototypes around customer needs.</li>

                                        </ul>
                                        <span className="read-more-target  ps-2">
                                            <ul className="px-3 py-0 mb-0">
                                                <li>Find creative ways to solve UX problems (e.g. usability, findability).</li>
                                                <li>Work with UI designers to implement attractive designs.</li>
                                                <li>Work with UI designers to implement attractive designs.</li>
                                                <li>Keep abreast of competitor products and industry trend.</li><br />
                                            </ul>
                                            <span className="py-3 pt-4"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className="ps-3 pt-2">
                                                <li>Proven experience as a UX Designer, UI Designer or similar role.</li>
                                                <li>Strong portfolio of design projects.</li>
                                                <li>Background in project management and research.</li>
                                                <li>Familiarity with interaction design and information architecture.</li>
                                                <li>Proficient in design software (e.g. UXPin, Balsamiq).</li>
                                                <li>Knowledge of HTML/CSS; JavaScript is a plus.</li>
                                                <li>Problem-solving aptitude.</li>
                                                <li>Analytical mind with a business acumen.</li>
                                                <li>Excellent communication skills.</li>
                                            </ul>
                                            <div>
                                                <b>Experience:</b>
                                                <p> Min. 1+ Experience (Fresher's can also apply)</p>
                                            </div>

                                            <a href="#forminput"
                                                className="py-1 mb-3 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more8" className="read-more-trigger_closed  servicefont pointer">Read More...</label>
                                <label for="read-more8" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/Business_Development_Manager.png" className="card-img-top text-center"
                                alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>UI/ UX Designer</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more99" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark">
                                        <ul className="ps-3 my-0">
                                            <li>Understand product specifications and user psychology.</li>
                                            <li>Conduct concept and usability testing and gather feedback.</li>
                                            <li>Create personas through user research and data.</li>
                                            <li>Define the right interaction model and evaluate its success.</li>

                                        </ul>
                                        <span className="read-more-target">

                                            <ul className="px-3 my-0">
                                                <li>Develop wireframes and prototypes around customer needs.</li>
                                                <li>Find creative ways to solve UX problems (e.g. usability, findability).</li>
                                                <li>Work with UI designers to implement attractive designs.</li>
                                                <li>Work with UI designers to implement attractive designs.</li>
                                                <li>Keep abreast of competitor products and industry trend.</li><br />
                                            </ul>

                                            <span className="py-3"><b>Roles & Responsibility:</b></span> <br />

                                            <ul className="ps-3 pt-2">
                                                <li>Proven experience as a UX Designer, UI Designer or similar role.</li>
                                                <li>Strong portfolio of design projects.</li>
                                                <li>Background in project management and research.</li>
                                                <li>Familiarity with interaction design and information architecture.</li>
                                                <li>Proficient in design software (e.g. UXPin, Balsamiq).</li>
                                                <li>Knowledge of HTML/CSS; JavaScript is a plus.</li>
                                                <li>Problem-solving aptitude.</li>
                                                <li>Analytical mind with a business acumen.</li>
                                                <li>Excellent communication skills.</li>
                                                <div> <b>Experience:</b></div>
                                                <span> Min. 1+ Experience (Fresher's can also apply)</span>
                                            </ul>

                                            <a href="#forminput"
                                                className="py-1 mt-2 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more99" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                                <label for="read-more99" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            </p>

                        </div>
                    </div>
                </div> */}


                <div className="col-12 col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/web.png" className="card-img-top text-center" alt="img not found" style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Digital Marketing Executive</b></span>

                            <p className="p1 py-1">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more9" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark mb-3">
                                        <ul className="px-3 py-0 my-0" style={{ height: '230px' }}>
                                            <li>Assist in the formulation of strategies to build a lasting digital
                                                connection
                                                with consumers.</li>
                                            <li>Plan and monitor the ongoing company presence on social media (Twitter,
                                                Facebook
                                                etc.).</li>
                                            <li>Launch optimized online adverts through Google Adwords, Facebook etc. to
                                                increase company and brand awareness.</li>
                                        </ul>
                                        <span className="read-more-target  ps-2">
                                            <ul className="px-3 py-0 mb-0">
                                                <li>Be actively involved in SEO efforts (keyword, image optimization etc.).</li>
                                                <li>Prepare online newsletters and promotional emails and organize their
                                                    distribution through various channels.</li>
                                                <li>Provide creative ideas for content marketing and update website.</li>
                                                <li>Collaborate with designers to improve user experience.</li>
                                                <li>Measure performance of digital marketing efforts using a variety of Web
                                                    analytics tools (Google Analytics, WebTrends etc.).</li>
                                                <li>Acquire insight in online marketing trends and keep strategies up-to-date.
                                                </li>
                                                <li>Maintain partnerships with media agencies and vendors.</li> <br />
                                            </ul>
                                            <span className="py-3 pt-4"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className="ps-3 pt-2">
                                                <li>Proven experience as Digital Marketing Executive or similar role.</li>
                                                <li>Excellent understanding of digital marketing concepts and best practices.</li>
                                                <li>Experience with B2C social media, Google Adwords and email campaigns and
                                                    SEO/SEM.</li>
                                                <li>Working knowledge of ad serving tools (e.g., DART, Atlas).</li>
                                                <li>Perfect knowledge of web analytics tools (e.g. Google Analytics, Net Insight,
                                                    Web Trends etc.).</li>
                                                <li>Skills and experience in creative content writing.</li>
                                                <li>Analytical mindset and critical thinking.</li>
                                                <li>Excellent communication and interpersonal skills.</li>
                                            </ul>
                                            <div>
                                                <b>Experience:</b>
                                                <p> Min. 1+ Experience (Fresher's can also apply)</p>
                                            </div>

                                            <a href="#forminput"
                                                className="py-1 mb-3 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more9" className="read-more-trigger_closed  servicefont pointer">Read More...</label>
                                <label for="read-more9" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12  col-lg-4 py-4">
                    <div className="card ">
                        <div className="card-body">
                            <img src="Assets/Images/vaccancy/digital-marketing.png" className="card-img-top text-center" alt="img not found"
                                style={{ width: '10%' }} />
                            <span className="card-title  px-3"><b>Digital Marketing Executive</b></span>

                            <p className="p1 py-1 mb-0">
                                <h5 className="py-3 fw-bold">Skills Required:</h5>

                                <input type="checkbox" hidden className="read-more-state" id="read-more9" />
                                <div className="read-more-wrap">
                                    <p className="p1 text-dark">
                                        <ul className="ps-3 my-0 py-0">
                                            <li>Assist in the formulation of strategies to build a lasting digital
                                                connection
                                                with consumers.</li>
                                            <li>Plan and monitor the ongoing company presence on social media (Twitter,
                                                Facebook
                                                etc.).</li>
                                            <li>Launch optimized online adverts through Google Adwords, Facebook etc. to
                                                increase company and brand awareness.</li>

                                        </ul>
                                        <span className="read-more-target ps-3">
                                            <ul className="px-3 my-0 py-0">
                                                <li>Be actively involved in SEO efforts (keyword, image optimization etc.).</li>
                                                <li>Prepare online newsletters and promotional emails and organize their
                                                    distribution through various channels.</li>
                                                <li>Provide creative ideas for content marketing and update website.</li>
                                                <li>Collaborate with designers to improve user experience.</li>
                                                <li>Measure performance of digital marketing efforts using a variety of Web
                                                    analytics tools (Google Analytics, WebTrends etc.).</li>
                                                <li>Acquire insight in online marketing trends and keep strategies up-to-date.
                                                </li>
                                                <li>Maintain partnerships with media agencies and vendors.</li> <br />
                                            </ul>
                                            <span className="py-3"><b>Roles & Responsibility:</b></span> <br />
                                            <ul className="ps-3 pt-2">
                                                <li>Proven experience as Digital Marketing Executive or similar role.</li>
                                                <li>Excellent understanding of digital marketing concepts and best practices.</li>
                                                <li>Experience with B2C social media, Google Adwords and email campaigns and
                                                    SEO/SEM.</li>
                                                <li>Working knowledge of ad serving tools (e.g., DART, Atlas).</li>
                                                <li>Perfect knowledge of web analytics tools (e.g. Google Analytics, Net Insight,
                                                    Web Trends etc.).</li>
                                                <li>Skills and experience in creative content writing.</li>
                                                <li>Analytical mindset and critical thinking.</li>
                                                <li>Excellent communication and interpersonal skills.</li>
                                                <div> <b>Experience:</b></div>
                                                <span> Min. 1+ Experience (Fresher's can also apply)</span>
                                            </ul>
                                            <a href="#forminput"
                                                className="py-1 mt-2 px-4 mx-auto apply_button mx-auto text-decoration-none border colorcharchol">APPLY</a>
                                        </span>
                                    </p>
                                </div>
                                <label for="read-more9" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                                <label for="read-more9" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            </p>

                        </div>
                    </div>
                </div> */}

            </div>
        </>
    )
}