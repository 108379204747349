import { BrightFutureTogether } from "../../BlogPage/BrightFutureTogether"
import { Footer } from "../../Footer"
import { Header } from "../../Header"
import { HeroSection } from "../../HeroSection"
import { InfoProductMaintenance } from "./InfoProductMaintenance"

export const ProductMaintenance =()=>{
    return(
        <>
         <Header active="Home"/>
            <HeroSection/>
            <InfoProductMaintenance/>
            <BrightFutureTogether/>
            <Footer/>
        </>
    )
}