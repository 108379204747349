import { BrightFutureTogether } from "../BlogPage/BrightFutureTogether"
import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"
import { AllReviews } from "./AllReviews"

export const ClientReviews = () => {
  return (
    <>
      <Header active="Clients" />
      <HeroSection />
      <AllReviews />
      <BrightFutureTogether />
      <Footer />
    </>
  )
}