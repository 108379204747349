export const WomensDay = () => {
  return (
    <>
      <div className="row p-5" id="event_photo" style={{background:"#d3d3d3"}}>
        <div className="col-12 col-md-10 mx-auto py-4">
          <h3 className="fw-bold colorcharchol text-center">Women's Day</h3>

          <div className="zoom-in-out-box2 m-auto">
            <hr />
          </div>
        </div>

        <div className=" col-12 col-md-6 col-lg-3 ds">
          <div className="gallery-item mb-3 text-center" >
            {/* <span class="yearwise">2024</span> */}
            <div>

              <a href="Assets/Images/event/womens_day-01-01-min.jpg" className="thumbnail">

                <span>
                  <img src="Assets/Images/event/womens_day-01-01-min.jpg" alt="" />
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className=" col-12 col-md-6 col-lg-3 ds">
          <div className="gallery-item mb-3 text-center" >
            <div>
              <a href="Assets/Images/event/e4.png" className="thumbnail">
                <span>
                  <img src="Assets/Images/event/e4.png" alt="" />
                </span>
              </a>
            </div>
            {/* <span class="yearwiseend">2023</span> */}
          </div>
        </div>

        <div className=" col-12 col-md-6 col-lg-3 ds">
          <div className="gallery-item mb-3 text-center" >
            {/* <span class="yearwise">2022</span> */}
            <div>
              <a href="Assets/Images/event/women_3.jpg" className="thumbnail">
                <span>
                  <img src="Assets/Images/event/women_3.jpg" alt="" />
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className=" col-12 col-md-6 col-lg-3 ds">
          <div className="gallery-item mb-3 text-center" >
            <div>
              <a href="Assets/Images/event/w-4-01.jpg" className="thumbnail">
                <span>
                  <img src="Assets/Images/event/w-4-01.jpg" alt="" />
                </span>
              </a>
            </div>
            {/* <span class="yearwiseend">2021</span> */}
          </div>
        </div>

      </div>
    </>
  )
}