import { useNavigate } from "react-router-dom"

export const OurServices = () => {
    const navigate = useNavigate()
    return (
        <>

            <div className="row px-lg-5 pb-5 px-3 charchole text-white" id="our_service">
                <div className="col-10 col-md-10 m-auto pt-5 pb-2 text-center">
                    <h3 className="fw-bold">OUR SERVICES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                    <p className=" pt-4  text-center">We are dedicated to providing a wide range of top-notch services that cater to
                        all your business needs. Our team of skilled professionals excels in delivering solutions that drive growth
                        and ensure your success in the digital landscape.
                    </p>
                </div>

                <div className=" col-12 col-lg-3 p-4 ">
                    <div className="our_service row bg-white py-4 px-2 cursor-pointer" onClick={() => navigate('/BiSolutions?ScreenName=Business Intelligence Solutions')}>
                        <div className="col-8 ">
                            <h5 className=" text-dark">BI <br /> Solutions
                                <span className="ps-3"><i class="fa-solid fa-arrow-right fs-6 text-dark"></i></span>
                            </h5>
                        </div>
                        <div className="col-4 d-flex align-item-center justify-content-center">
                            <img src="Assets/Images/services/Solutions.png" alt="Image not found" width={60} />
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-lg-3 p-4 ">
                    <div className="our_service row bg-white py-4 px-2 cursor-pointer" onClick={() => navigate('/DataAnalytics?ScreenName=Data Analytics')}>
                        <div className="col-8 ">
                            <h5 className=" text-dark">Data  <br /> Analytics
                                <span className="ps-3"><i class="fa-solid fa-arrow-right fs-6 text-dark"></i></span>
                            </h5>
                        </div>
                        <div className="col-4 d-flex align-item-center justify-content-center">
                            <img src="Assets/Images/services/data-analytics.png" alt="Image not found" width={60} />
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-lg-3 p-4 ">
                    <div className="our_service row bg-white py-4 cursor-pointer" onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development')}>
                        <div className="col-8 ">
                            <h5 className=" text-dark">Web  <br /> Development
                                <span className="ps-3"><i class="fa-solid fa-arrow-right fs-6 text-dark"></i></span>
                            </h5>
                        </div>
                        <div className="col-4 d-flex align-item-center justify-content-center">
                            <img src="Assets/Images/services/Web-development.png" alt="Image not found" width={60} />
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-lg-3 p-4 ">
                    <div className="our_service row bg-white py-4 cursor-pointer" onClick={() => navigate('/MobileAppDevelopment?ScreenName=Mobile Development')}>
                        <div className="col-8 ">
                            <h5 className=" text-dark">Mobile  <br /> Development
                                <span className="ps-3"><i class="fa-solid fa-arrow-right fs-6 text-dark"></i></span>
                            </h5>
                        </div>
                        <div className="col-4 d-flex align-item-center justify-content-center">
                            <img src="Assets/Images/services/Mobile-development.png" alt="Image not found" width={60} />
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-lg-3 p-4 py-lg-0 px-lg-4 ">
                    <div className="our_service row bg-white py-4 cursor-pointer" onClick={() => navigate('/CustomizedSolution?ScreenName=Customized Solutions')}>
                        <div className="col-8 ">
                            <h5 className=" text-dark">Customized   <br /> Solutions
                                <span className="ps-3"><i class="fa-solid fa-arrow-right fs-6 text-dark"></i></span>
                            </h5>
                        </div>
                        <div className="col-4 d-flex align-item-center justify-content-center">
                            <img src="Assets/Images/services/Customized.png" alt="Image not found" width={60} />
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-lg-3 p-4 py-lg-0 px-lg-4 ">
                    <div className="our_service row bg-white py-4 cursor-pointer" onClick={() => navigate('/ProductDevelopment?ScreenName=Product Development')}>
                        <div className="col-8 ">
                            <h5 className=" text-dark">Product   <br /> Development
                                <span className="ps-3"><i class="fa-solid fa-arrow-right fs-6 text-dark"></i></span>
                            </h5>
                        </div>
                        <div className="col-4 d-flex align-item-center justify-content-center">
                            <img src="Assets/Images/services/Product_development.png" alt="Image not found" width={60} />
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-lg-3 p-4 py-lg-0 px-lg-4 ">
                    <div className="our_service row bg-white py-4 cursor-pointer" onClick={() => navigate('/ProductMaintenance?ScreenName=Product Maintenance')}>
                        <div className="col-8 ">
                            <h5 className=" text-dark">Product   <br /> Maintenance
                                <span className="ps-3"><i class="fa-solid fa-arrow-right fs-6 text-dark"></i></span>
                            </h5>
                        </div>
                        <div className="col-4 d-flex align-item-center justify-content-center">
                            <img src="Assets/Images/services/product-management.png" alt="Image not found" width={60} />
                        </div>
                    </div>
                </div>

                <div className=" col-12 col-lg-3 p-4 py-lg-0 px-lg-4 ">
                    <div className="our_service row bg-white py-4 cursor-pointer" onClick={() => navigate('/GraphicDesign?ScreenName=Graphic Design')}>
                        <div className="col-8 ">
                            <h5 className=" text-dark">Graphic   <br /> Design
                                <span className="ps-3"><i class="fa-solid fa-arrow-right fs-6 text-dark"></i></span>
                            </h5>
                        </div>
                        <div className="col-4 d-flex align-item-center justify-content-center">
                            <img src="Assets/Images/services/Graphic-design.png" alt="Image not found" width={60} />
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 col-md-6 col-lg-4 py-4 text-center">
                    <div className="col1 box">
                        <div className="card px-3">
                            <span className="fab1 fa-envira1 mt-3"><img src="Assets/Images/BI_Solution.png" alt="Image not found"
                                className="serviceimg" /></span>
                            <p className="h4 pt-4">BI Solutions</p>
                            <input type="checkbox" hidden className="read-more-state" id="read-more2" />
                            <div className="read-more-wrap">
                                <p className="p1 text-muted">
                                    Businesses today demand instant, meaningful insights from their data as it gets created. They need mechanisms
                                    <span className="read-more-target text-muted ps-2">
                                        to empower their users to apply operational intelligence and make informed decisions in  time.
                                    </span>
                                </p>
                            </div>
                            <label for="read-more2" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                            <label for="read-more2" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            <a href="javascript:void(0)"
                                onClick={() => navigate('/BiSolutions?ScreenName=Business Intelligence Solutions&Name=Services')}
                                className="fa-solid fa-angle-right p-3 pointer text-black text-decoration-none"></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 py-4 text-center">
                    <div className="col1 box">
                        <div className="card px-3">
                            <span className="fab1 fa-envira1 mt-3">
                                <img src="Assets/Images/analytics.png" alt="Image not found"
                                    className="serviceimg" /></span>
                            <p className="h4 pt-4">Data Analytics</p>

                            <input type="checkbox" hidden className="read-more-state" id="read-more" />
                            <div className="read-more-wrap">
                                <p className="p1 text-muted">
                                    With our comprehensive and Customized suite of enterprise BI
                                    solutions, we strive to put data to work by leveraging modern
                                    <span className="read-more-target text-muted ps-2">
                                        BI tools and expertise in data
                                        analytics.
                                    </span>
                                </p>
                            </div>
                            <label for="read-more" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                            <label for="read-more" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            <a href="javascript:void(0)"
                                onClick={() => navigate('/DataAnalytics?ScreenName=Data Analytics&Name=Services')}
                                className="fa-solid fa-angle-right p-3 pointer text-black text-decoration-none"></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 py-4 text-center">
                    <div className="col1 box">
                        <div className="card px-3">

                            <span className="fab1 fa-envira1 mt-3"><img src="Assets/Images/Web Development.png" alt="Image not found"
                                className="serviceimg" /></span>

                            <p className="h4 pt-4">Web Development</p>

                            <input type="checkbox" hidden className="read-more-state" id="read-more1" />
                            <div className="read-more-wrap">
                                <p className="p1 text-muted">
                                    We are a leading website design and web development company helping some of
                                    the most
                                    recognized brands with
                                    <span className="read-more-target text-muted ps-2">
                                        digital landscape presence through custom
                                        web development services.
                                    </span>
                                </p>
                            </div>
                            <label for="read-more1" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                            <label for="read-more1" className="read-more-trigger_opened pb-4 pointer">Read Less</label>
                            <a href="javascript:void(0)"
                                onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')}
                                className="fa-solid fa-angle-right p-3 pointer text-black text-decoration-none"></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 py-4 text-center">
                    <div className="col1 box">
                        <div className="card px-3">

                            <span className="fab1 fa-envira1 mt-3"><img src="Assets/Images/Mobile Development.png" alt="Image not found"
                                className="serviceimg" /></span>

                            <p className="h4 pt-4">Mobile Development</p>

                            <input type="checkbox" hidden className="read-more-state" id="read-more3" />
                            <div className="read-more-wrap">
                                <p className="p1 text-muted">
                                    Leverage our comprehensive approach for industry-specific solutions.
                                    We  provide our clients with bothh cross- platform  or
                                    <span className="read-more-target text-muted ps-2">
                                        native apps considering
                                        all business needs.
                                    </span>
                                </p>
                            </div>
                            <label for="read-more3" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                            <label for="read-more3" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            <a href="javascript:void(0)"
                                onClick={() => navigate('/MobileAppDevelopment?ScreenName=Mobile Development&Name=Services')}
                                className="fa-solid fa-angle-right p-3 pointer text-black text-decoration-none"></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 py-4 text-center">
                    <div className="col1 box">
                        <div className="card px-3">

                            <span className="fab1 fa-envira1 mt-3"><img src="Assets/Images/customization.png" alt="Image not found"
                                className="serviceimg " /></span>

                            <p className="h4 pt-4">Customized Solutions</p>

                            <input type="checkbox" hidden className="read-more-state" id="read-more4" />
                            <div className="read-more-wrap">
                                <p className="p1 text-muted">
                                    Get CDAT for consulting, designing, developing, testing and deploying any enterprise custom software development
                                    <span className="read-more-target text-muted ps-2">
                                        services. Software development is the whole end to end process.
                                    </span>
                                </p>
                            </div>
                            <label for="read-more4" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                            <label for="read-more4" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            <a href="javascript:void(0)"
                                onClick={() => navigate('/CustomizedSolution?ScreenName=Customized Solutions&Name=Services')}
                                className="fa-solid fa-angle-right p-3 pointer text-black text-decoration-none"></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 py-4 text-center">
                    <div className="col1 box">
                        <div className="card px-4">

                            <span className="fab1 fa-envira1 mt-3"><img src="Assets/Images/Product Development.png" alt="Image not found"
                                className="serviceimg" /></span>

                            <p className="h4 pt-4">Product Development</p>

                            <input type="checkbox" hidden className="read-more-state" id="read-more5" />
                            <div className="read-more-wrap">
                                <p className="p1 text-muted">
                                    In today’s cost conscious, yet risk-averse business environment, CDAT offers  comprehensive product development
                                    <span className="read-more-target text-muted ps-2">
                                        solutions specifically tailored to meet the critical business needs of each
                                        of our
                                        clients.
                                    </span>
                                </p>
                            </div>
                            <label for="read-more5" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                            <label for="read-more5" className="read-more-trigger_opened pb-4 pointer">Read Less</label>


                            <a href="javascript:void(0)"
                                onClick={() => navigate('/ProductDevelopment?ScreenName=Product Development&Name=Services')}
                                className="fa-solid fa-angle-right p-3 pointer text-black text-decoration-none"></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 py-5 text-center m-auto">
                    <div className="col1 box">
                        <div className="card px-4">

                            <span className="fab1 fa-envira1 mt-3"><img src="Assets/Images/Product Maintenance.png" alt="Image not found"
                                className="serviceimg" /></span>

                            <p className="h4 pt-4">Product Maintenance</p>

                            <input type="checkbox" hidden className="read-more-state" id="read-more6" />
                            <div className="read-more-wrap">
                                <p className="p1 text-muted">
                                    We offer you world-className and timely product maintenance and quality support
                                    services. Product
                                    development is
                                    <span className="read-more-target text-muted ps-2">
                                        not complete until and unless appropriate steps are taken towards its maintenance
                                        and upkeep..
                                    </span>
                                </p>
                            </div>
                            <label for="read-more6" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                            <label for="read-more6" className="read-more-trigger_opened pb-4 pointer">Read Less</label>

                            <a href="javascript:void(0)"
                                onClick={() => navigate('/ProductMaintenance?ScreenName=Product Maintenance&Name=Services')}
                                className="fa-solid fa-angle-right p-3 pointer text-black text-decoration-none"></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 py-5 text-center m-auto">
                    <div className="col1 box">
                        <div className="card px-3">

                            <span className="fab1 fa-envira1 mt-3"><img src="Assets/Images/color-palette.png" alt="Image not found"
                                className="serviceimg" /></span>

                            <p className="h4 pt-4">Graphic Design</p>


                            <input type="checkbox" hidden className="read-more-state" id="read-more7" />
                            <div className="read-more-wrap">
                                <p className="p1 text-muted">
                                    Our designers boast a portfolio of successful projects across various industries,
                                    showcasing their mastery of visual aesthetics.
                                    <span className="read-more-target text-muted ps-2">
                                        We align our creative efforts with
                                        your brand's goals, ensuring that each design serves a specific purpose in achieving your objectives.
                                        Our designs aren't just visually striking they're also meticulously crafted to communicate your brand's
                                        message effectively..
                                    </span>
                                </p>
                            </div>
                            <label for="read-more7" className="read-more-trigger_closed pb-4 servicefont pointer">Read More...</label>
                            <label for="read-more7" className="read-more-trigger_opened pb-4 pointer">Read Less</label>


                            <a href="javascript:void(0)"
                                onClick={() => navigate('/GraphicDesign?ScreenName=Graphic Design&Name=Services')}
                                className="fa-solid fa-angle-right p-3 pointer text-black text-decoration-none"
                            ></a>
                        </div>
                    </div>
                </div> */}

            </div>

        </>
    )
}