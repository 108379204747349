export const ConnectMap = () => {
    return (
        <>
            <div class="row p-lg-5 border" id="contact">

                {/* <div class="col-12 col-md-12 m-auto py-5 text-center charchole">
                    <h3 class="fw-bold white">CONNECT</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div> */}

                <div class="col-12  border px-0">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.520835968536!2d73.82188887436637!3d18.460052671021643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc295e613b54b99%3A0x466d7a51d1b0faef!2scenter%20for%20development%20advance%20technologies!5e0!3m2!1sen!2sin!4v1688378218568!5m2!1sen!2sin"
                        class="map_cdat" style={{ height: '60vh', width: '100%' }} allowfullscreen=" " loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                {/* style={{ backgroundColor: '#e7e7e7' }} */}
                {/* <div className="col-6 contact-shadow pt-4 border p-5" style={{background:'#eeeeee'}} >

                    <div className="row p-4 pt-5" >
                        <div className="col-2">
                            <img src="Assets/Images/location.png" alt="Image not found" width={40} />
                        </div>
                        <div className="col-10">
                            <address class="" style={{ textAlign: 'justify' }}>
                                <a href="https://goo.gl/maps/kiNNdJtD1oi5KTAS6" target="_blank" class="text-dark text-decoration-none a-hover">
                                    103-104, A2,  Omkar Nandan, Navale Bridge, Opp.Navale IT Park, Vadgaon Bk. Pune-411041, Maharashtra, India.</a>
                            </address>
                        </div>
                    </div>

                    <div className="row p-4" >
                        <div className="col-2">
                            <img src="Assets/Images/call.png" alt="Image not found" width={40} />
                        </div>
                        <div className="col-10">
                            <a href="tel:8862082200" class=" text-decoration-none a-hover text-dark ">+91 8862 0822 00</a> <br />
                            <a href="tel:9172650917" class=" text-decoration-none a-hover text-dark ">+91 9172 6509 17</a>
                        </div>
                    </div>

                    <div className="row p-4" >
                        <div className="col-2">
                            <img src="Assets/Images/gmail.png" alt="Image not found" width={40} />
                        </div>
                        <div className="col-10">
                            <a href="mailto:info@cdat.co.in" class=" text-decoration-none a-hover text-dark ">info@cdat.co.in</a> <br />
                            <a href="sales@cdat.co.in" class=" text-decoration-none a-hover text-dark ">sales@cdat.co.in</a>
                        </div>
                    </div>


                </div> */}


                    {/* <div class="col-12 col-md-6 text-center  py-3  ">
                            <h2 class="silver"><i class="fa-solid fa-phone"></i></h2>
                            <h4><b>PHONE</b></h4>
                            <a href="tel:8862082200" class=" text-decoration-none a-hover">+91 8862082200</a> <br />
                            <a href="tel:9172650917" class=" text-decoration-none a-hover">+91 9172650917</a>
                        </div>

                        <div class="col-12 col-md-6 text-center  py-3  ">
                            <h2 class="silver"><i class="fa-solid fa-envelope"></i></h2>
                            <h4><b>EMAIL</b></h4>
                            <a href="mailto:info@cdat.co.in" class=" text-decoration-none a-hover">info@cdat.co.in</a> <br />
                            <a href="sales@cdat.co.in" class=" text-decoration-none a-hover">sales@cdat.co.in</a>
                        </div>

                        <div class="col-12 text-center  pt-2   px-lg-5">
                            <h2 class="silver"><i class="fa-solid fa-location-dot"></i></h2>
                            <h4><b>OFFICE</b></h4>
                            <address class="">
                                <a href="https://goo.gl/maps/kiNNdJtD1oi5KTAS6" target="_blank" class="text-decoration-none a-hover">
                                    103, A2,  Omkar Nandan, Navale Bridge, Opp.Navale IT Park, Vadgaon Bk. Pune 411041 Maharashtra, India.</a>
                            </address>
                        </div> */}

            </div>
        </>
    )
}