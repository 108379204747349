import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"


export const AssetManagementSystem = () => {
    return (
        <>
            <Header active="Products" />
            <HeroSection />

            {/* --------------------------------- */}
            <div class="row bgdotimg " id="AMS">
                <div class="col-12 col-md-10 mx-auto py-4">
                    <h3 class="fw-bold colorcharchol text-center">IT (Asset) Management System </h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div class="col-12 col-md-10 mx-auto py-3 text-center">
                    <p class="alignment text-center">
                        Earlier there were no such system in government offices which would help officers monitor and update with various inventory in the offices such as Hardware Parts. Asset (IT) Management System is specially curated for the government offices for managing and monitoring of Inventory, Complaints and Maintenance of the Assets in particular department.  </p>
                    <p>
                        Officers of the department can have comprehensive data of these assets on their dashboard which will helped them to equip their work in advance to avoid further break downs of the assets. They can manage & monitor IT asset inventory, raise and resolve Complaints flow, asset wise preventive maintenance work, tracking and managing various assets within the organization.
                    </p>
                </div>

            </div>

            <div class="row py-5 px-3" id="key">
                <div class="col-12 col-md-10 mx-auto py-1">
                    <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div class="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Asset Tracking and Inventory Management.</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Asset Wise Inventory updating & maintenance along with maintenance of expenditure maintenance.</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>QR Code wise asset tracking (with geo-tagged facility).</b> </p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Asset allocation and transfer to table User.</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Asset wise complaints raised and resolved tracking mechanism.</b></p>
                    </div>
                </div>

                <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div class="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Asset wise penalty for delay work. </b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            {/* <div class="row" id="Brochure">
                <div class="col-12 charchole py-5">
                    <h4 class="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p class="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div class="py-4 text-center">
                        <a onClick={() => window.open('http://cdat.co.in/doc/Tree_Risk_Assessment_System.pdf')}
                            class="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div> */}
            {/* <!--end of Brochure row--> */}



            <Footer />
        </>
    )
}